import {createContext, useContext, useEffect, useState} from "react";
import { onAuthStateChanged } from "firebase/auth"
import { signOut } from "firebase/auth";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {auth, db} from "../utils/firebaseApp";
import LoadingScreen from "../components/shared-components/LoadingScreen";


const AuthContext = createContext(undefined)

export const AuthContextProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsAuth(!!user);
        }, error => setIsAuth(false));
        return () => unsubscribe();
    }, [])

    useEffect(() => {
        if (isAuth) {
            console.log("Logged in fetch user...")
            fetchUser();
        }
    }, [isAuth])

    const fetchUser = async () => {
        if (auth?.currentUser?.uid) {
            try {
                setLoading(true);
                const docRef = doc(db, "users", auth?.currentUser?.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap?.exists()) {
                    const userDetails = {...docSnap?.data()};
                    setUser(userDetails || null);
                } else {
                    const userDetails = {
                        uid: auth?.currentUser?.uid,
                        displayName: auth.currentUser?.displayName,
                        email: auth?.currentUser?.email,
                        photoURL: auth?.currentUser?.photoURL,
                    }
                    await setDoc(doc(db, "users", auth.currentUser.uid), userDetails);
                    setUser(userDetails || null);
                }
                setIsAuth(true);
                setLoading(false);
            } catch (e) {
                console.log(e);
                await handleLogout();
            }
        }
    }

    const handleLogout = async () => {
        await signOut(auth);
        setIsAuth(false);
        setUser(null);
    }

    const updateUser = (details) => {
        setUser(prev => ({...prev, ...details}))
    }
    
    return (
        <AuthContext.Provider
            value={{
                isAuth: isAuth,
                user,
                handleLogout,
                updateUser
            }}
        >
            {isAuth === null || loading ? <LoadingScreen /> : children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)