import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {ThemeProvider} from "@mui/material/styles";
import {
    ButtonBase,
    CircularProgress,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    Toolbar,
    useTheme,
} from "@mui/material";
import {Divider} from "@mui/material";
import GoogleButton from "react-google-button";
import logoImg from "../../assets/logo/Ella.svg";

import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import PasswordResetModal from "./PasswordResetModal";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    orderBy,
} from "firebase/firestore";
import {AppContext} from "../../utils/AppContext";
import {auth, db} from "../../utils/firebaseApp";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {toast} from "react-toastify";

const BUTTON_MESSAGES = {
    SIGN_IN: "Log In",
    SIGNING_IN: "Signing in",
    CREATE_ACCOUNT: "Creating your account",
};

export function LoginPage({user}) {
    const navigate = useNavigate();

    const provider = new GoogleAuthProvider();
    const theme = useTheme();
    const [checkIsLoggedIn, setCheckIsLoggedIn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [pwdResetModal, setPwdResetModal] = useState(false);
    const [message, setMessage] = useState(BUTTON_MESSAGES.SIGN_IN);
    const [error, setError] = useState("");

    const handleCheckbox = () => {
        setChecked(!checked);
    };

    const {
        dispatch,
    } = useContext(AppContext);

    function handleLoginGoogle() {
        console.log("login google");
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // await hydrateUserData();
                navigate("/dashboard")
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // async function hydrateUserData() {
    //     setLoading(true);
    //     try {
    //         const docRef = doc(db, "users", auth.currentUser.uid);
    //         const docSnap = await getDoc(docRef);
    //         if (docSnap.exists()) {
    //             const currentUser = docSnap.data();
    //             dispatch({currentUser: currentUser});
    //
    //             navigate("/dashboard");
    //         } else {
    //             console.log("does not exist");
    //             const blankUser = {
    //                 uid: auth.currentUser.uid,
    //                 joined: Date.now(),
    //                 surveys: [],
    //                 accountType: "standard",
    //                 email: auth.currentUser.email,
    //             };
    //             try {
    //                 await setDoc(doc(db, "users", auth.currentUser.uid), blankUser);
    //                 dispatch({currentUser: blankUser});
    //                 navigate("/dashboard");
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const handleLogin = async () => {
        await signInWithEmailAndPassword(auth, email, password);
        // await hydrateUserData();
    }

    const handleRegister = async () => {
        await createUserWithEmailAndPassword(auth, email, password);
    }

    const handleLoginEmailPassword = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setMessage(BUTTON_MESSAGES.SIGNING_IN);
            await handleLogin();
            navigate("/dashboard")
        }  catch (error) {
            setMessage(BUTTON_MESSAGES.CREATE_ACCOUNT);
            const errorMessage = error?.message;
            setError(errorMessage);
            try {
                await handleRegister();
                await handleLogin();
                // await hydrateUserData();
            } catch (errorRegister) {
                const errorMessage = error?.message;
                setError(errorMessage);
            }
        } finally {
            setLoading(false);
            setMessage(BUTTON_MESSAGES.SIGN_IN);
        }
    }

    useEffect(() => {
        async function userData() {
            setCheckIsLoggedIn(true);
            console.log("Hydrate user data start loading");
            // await hydrateUserData();
            console.log("Hydrate user data end loading");
            setCheckIsLoggedIn(false);
        }

        if (user) {
            userData();
        } else {
            setCheckIsLoggedIn(false);
        }
    }, [user]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {checkIsLoggedIn ? (
                <Stack
                    direction={"row"}
                    sx={{width: "100%", height: "100%"}}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <CircularProgress/>
                </Stack>
            ) : (
                <Grid
                    container
                    sx={{height: "100%", padding: "12px 0px 0px 0px"}}
                    alignItems={"center"}
                >
                    <Grid item xs={12} md={6} sx={{height: "100%"}}>
                        <Stack
                            direction={"column"}
                            justifyContent={"space-between"}
                            sx={{height: "100%", px: 2}}
                        >
                            {/* logo */}
                            <div>
                                <img
                                    src={logoImg}
                                    alt={"Logo"}
                                    style={{height: 55, justifyContent: "center"}}
                                />
                            </div>
                            <Box
                                component={"form"}
                                onSubmit={handleLoginEmailPassword}
                                sx={{maxWidth: "400px", mx: "auto", p: {xs: "0px 32px"}}}
                            >
                                <Typography variant={"h1"} className={"centered"}>
                                    <strong style={{fontWeight: 600}}>Log In</strong>
                                </Typography>
                                <Typography
                                    variant={"subtitle2"}
                                    className={"centered"}
                                    style={{marginTop: 13, color: "#878787"}}
                                >
                                    If you don't have an account, signing in will automatically
                                    create an account
                                </Typography>
                                <Box sx={{my: 2}}>
                                    <TextField
                                        style={{marginTop: "12px"}}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        fullWidth
                                        disabled={loading}
                                        variant={"standard"}
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <FormControl
                                        variant="standard"
                                        disabled={loading}
                                        fullWidth
                                        sx={{my: 1}}
                                    >
                                        <InputLabel htmlFor="password">Password *</InputLabel>
                                        <Input
                                            type={checked ? "text" : "password"}
                                            required
                                            fullWidth
                                            disabled={loading}
                                            variant={"standard"}
                                            name="password"
                                            label="Password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            autoComplete="current-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleCheckbox}
                                                        sx={{width: 18}}
                                                        // onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {checked ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                                <Button
                                    type="submit"
                                    className={"gradient-btn"}
                                    disabled={loading}
                                    onClick={handleLoginEmailPassword}
                                    sx={{marginTop: "18px"}}
                                >
                                    <Typography>{message}</Typography>
                                </Button>
                                <ButtonBase onClick={() => setPwdResetModal(true)}>
                                    <Typography
                                        variant="body1"
                                        sx={{cursor: "pointer", marginTop: "8px"}}
                                    >
                                        Forgot password?
                                    </Typography>
                                </ButtonBase>
                                <div style={{marginTop: 50, alignItems: "center"}}>
                                    <Divider sx={{my: 2}}>
                                        <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                                fontSize: "0.85em",
                                                color: "#818181",
                                            }}
                                        >
                                            or continue with
                                        </Typography>
                                    </Divider>
                                </div>
                                <Box sx={{textAlign: "-webkit-center"}}>
                                    <GoogleButton
                                        className={"google-btn"}
                                        onClick={() => handleLoginGoogle()}
                                        type={"light"}
                                    />
                                </Box>
                                <Box sx={{my: 2}}>
                                    <Typography color={"error"}>
                                        {error &&
                                            "That combination of username and password wasn't found"}
                                    </Typography>
                                </Box>
                            </Box>
                            <div>
                                <Typography
                                    variant={"subtitle2"}
                                    sx={{py: 1, color: "#9A9A9A"}}
                                >
                                    &copy; 2024 Ella Surveys
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className={"login-bg"}
                        sx={{
                            bgcolor: "#5271FF",
                            p: 5,
                            height: "100%",
                            display: {
                                xs: "none",
                                md: "flex",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                background: "rgba(255,255,255, 0.15)",
                                p: 5,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Typography variant={"h1"} color={"white"}>
                                <strong style={{fontWeight: 600}}>Start your journey here.</strong>
                            </Typography>
                            <Typography variant={"h3"} color={"white"} sx={{my: 2}}>
                                <strong style={{fontWeight: 600}}> Unlimited feedback without barriers.</strong>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <PasswordResetModal
                open={pwdResetModal}
                setOpen={setPwdResetModal}
                title={"Reset Password"}
                description={
                    "Enter your email to receive a link to create a new password."
                }
                actionIfYes={() => {
                    sendPasswordResetEmail(auth, email).then(() => {
                        setPwdResetModal(false);
                        toast.success(
                            "We've sent you an email to reset your password; please check your inbox.",
                        );
                    });
                }}
                actionIfNo={() => setPwdResetModal(false)}
                closeBtnText={"cancel"}
                yesBtnText={"reset"}
                value={email}
                setValue={setEmail}
            ></PasswordResetModal>
        </ThemeProvider>
    );
}
