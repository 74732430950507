import {Box, Chip, IconButton, Stack, TextField, Typography, Menu, Button, Divider, Badge} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import React, {useEffect, useMemo, useState} from "react";
import {IconFilter} from "@tabler/icons-react";


export const TokensFilter = ({selectedFilter, updateFilter}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (selectedFilter) {
      setFilter(selectedFilter ? {...selectedFilter} : {});
    }
  }, [selectedFilter]);

  const onApply = () => {
    updateFilter({...filter});
    handleClose();
  }
  const onReset = () => {
    setFilter({used: null, dateFrom: null, dateTo: null});
    updateFilter({used: null, dateFrom: null, dateTo: null});
    handleClose();
  }

  const countFilter = useMemo(() => {
    return Object.values(selectedFilter)?.filter(i => i !== null).length;
  }, [selectedFilter]);

  return (
    <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Badge badgeContent={countFilter} color={"error"} size="small">
            <IconFilter/>
          </Badge>
        </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          horizontal: "right"
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom"
        }}
        PaperProps={{
          sx: {px: 2, py: 1}
        }}
      >
        <Stack direction={"column"} gap={1}>
          <Typography fontWeight={600}>Filter Tokens By:</Typography>
          <Divider sx={{width: "95%", mx: 'auto'}}/>
          <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
            <Chip label={"All"} color={filter?.used === null ? "primary" : "default"}
                  onClick={() => setFilter(prev => ({...prev, used: null}))}/>
            <Chip label={"Used"} color={filter?.used === true ? "primary" : "default"}
                  onClick={() => setFilter(prev => ({...prev, used: true}))}/>
            <Chip label={"Not used"} color={filter?.used === false ? "primary" : "default"}
                  onClick={() => setFilter(prev => ({...prev, used: false}))}/>
          </Stack>
          <Box>
            <Typography fontSize={"0.9rem"}>Date From</Typography>
            <DatePicker
              InputAdornmentProps={{sx: {pr: 2}}} inputFormat={"DD.MM.YYYY"}
              value={filter?.dateFrom ? moment(filter?.dateFrom) : null} // Convert timestamp to Moment.js object
              onChange={(newValue) => setFilter(prev => ({...prev, dateFrom: moment(newValue).valueOf()}))}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box>
            <Typography fontSize={"0.9rem"}>Date To</Typography>
            <DatePicker
              InputAdornmentProps={{sx: {pr: 2}}} inputFormat={"DD.MM.YYYY"}
              value={filter?.dateTo ? moment(filter?.dateTo) : null} // Convert timestamp to Moment.js object
              onChange={(newValue) => setFilter(prev => ({...prev, dateTo: moment(newValue).valueOf()}))}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Divider sx={{width: "95%", mx: 'auto'}}/>
          <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
            <Button onClick={onReset} variant={"outlined"} className={"gradient-outlined-btn"} sx={{flex: 1}}>
              Reset
            </Button>
            <Button onClick={onApply} variant={"contained"} className={"gradient-btn"} sx={{flex: 1}}>
              Apply
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  )
}