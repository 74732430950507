import React, {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from "../../utils/AppContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Header} from "../../components/main-header/HeaderElla";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {collection, doc, getDoc, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {removeDuplicatesByProperty, sortArrayByNumericObjectProperty} from "../../utils/sharedFunctions";
import uuid from "react-uuid";
import {toast} from "react-toastify";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import * as activeSurvey from "countries-list";
import GridLayout from "react-grid-layout";
import logoImg from "../../assets/logo/Ella.svg";

const PublicReportPage = () => {
    const [width, setWidth] = useState(1200); // default width
    const containerRef = useRef(null); // reference for the parent container

    let {uid} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const [questionObjects, setQuestionObjects] = useState([]);
    const [answerObjects, setAnswerObjects] = useState([]);
    const {state: {currentUser}, dispatch} = useContext(AppContext);

    // Update layout width on resize or load
    useEffect(() => {
            const updateWidth = () => {
                if (containerRef.current) {
                    setWidth(containerRef.current.offsetWidth - 16); // set width based on the container div
                }
            };

            updateWidth(); // update the width when the component mounts
            window.addEventListener('resize', updateWidth); // update the width on window resize

            return () => window.removeEventListener('resize', updateWidth); // clean up on unmount
    }, []);

    useEffect(() => {
        fetchSurveyData(uid)
    }, [uid])

    async function fetchSurveyData(reportUid) {
        if (!reportUid) navigate('/404')
        setLoading(true);
        try {
            const reportRef = doc(db, "reports", uid);
            const reportSnapshot = await getDoc(reportRef);
            if (!reportSnapshot.exists()) navigate('/404')

            const report = reportSnapshot.data();
            const questionQuery = query(collection(db, "questions"), where("surveyUid", "==", report.surveyUid));
            const questionSnapshot = await getDocs(questionQuery);
            let questionObjects = []
            let answerObjects = []
            questionSnapshot.forEach((doc) => {
                questionObjects.push(doc.data())
            });

            const answerQuery = query(collection(db, "answers"), where("surveyUid", "==", report.surveyUid), orderBy("timestamp"),);
            const answerSnapshot = await getDocs(answerQuery);
            answerSnapshot.forEach((doc) => {
                answerObjects.push(doc.data());
            });
            const questionToDisplay = [];
            for (const question of questionObjects) {
                if (report?.questions?.includes(question?.uid)) {
                    const position = report?.positions?.find(i => i?.questionUid === question?.uid)?.position || {x: 0, y: 0, w: 6, h: 3, i: question?.uid, };
                    questionToDisplay.push({...question, position});
                }
            }
            console.log("Edit report", questionToDisplay)
            setReport(report)
            setQuestionObjects(questionToDisplay);
            // setQuestionObjects(sortArrayByNumericObjectProperty(questionObjects, "orderNumber"))
            setAnswerObjects(answerObjects)
            dispatch({answerObjects: answerObjects})

        } catch (error) {
            console.log(error)
            if (error.response) {
                toast.error("error", "Something went wrong, please try again!")
            }
        } finally {
            setLoading(false);
        }
    }

    if (loading || !report) return <LoadingScreen/>

    return (
        <>
            <Box ref={containerRef} sx={{px: {xs: 0, md: 5}, maxWidth: 'lg', pt: 3, pb: 8, mx: {xs: 1, md: "auto"}}}>
                <Box sx={{p: 3}}>
                    <TitleDate title={report.name}/>
                </Box>
                <GridLayout
                  className="layout"
                  isDraggable={false}
                  isResizable={false}
                  cols={12}
                  rowHeight={50}
                  width={width}
                >
                {questionObjects
                    ?.map((item, index) =>
                      <Box key={item.uid || item?.id || index} className={"widget"}
                           sx={{border: "0 !important", p: 1}}
                           data-grid={{
                               x: item?.position?.x || 0,
                               y: item?.position?.y || 0,
                               w: item?.position?.w || 4,
                               h: item?.position?.h || 3,
                               minW: 2,
                               minH: 1
                           }}
                      >
                        <QuestionGraphWrapper
                            question={item}
                            index={index + 1}
                        />
                      </Box>
                    )}
                </GridLayout>
                <Box sx={{position: "fixed", bottom: 10, right: 10}}>
                    <Link to={"/"}>
                        <img src={logoImg} alt={"Logo"} height={30}/>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default PublicReportPage;
