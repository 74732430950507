import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import React from "react";
import {InputText} from "./InputText";


export const TextQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  return (
    <>
      <QuestionTitle title={answer?.question ? <>{answer?.question} {answer?.required ? <span style={{color: "red"}}>*</span> : null}</> : "-"} />
      <InputText value={answer?.text} onChange={(e) => setAnswer(prev => ({...prev, text: e?.target?.value}))} />
    </>
  )
}