import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import recordingCompletedLt from "../assets/recording/lottie_ella_recording_completed.json";
import Lottie from "lottie-react";

const ThankYouPage = () => {
  return (
    <Grid container sx={{minHeight: '100vh', height: '100vh', p: {xs: 3, sm: 3, md: 5} }} justifyItems={'center'}
          justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
      <Grid item maxWidth={"sm"} sx={{display: "flex", direction: "column", gap: 2}}>
        <Stack direction={"column"} gap={2} alignItems={"center"}>
          <Lottie
            animationData={recordingCompletedLt}
            loop={false}
            options={{
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={400}
            width={400}
          />
          <Typography sx={{fontSize: {xs: "1rem", sm: "1.2rem", md: "1.5rem", lg: "2rem"}, color: "secondary", fontWeight: 600 }} align={"center"}>
            The survey has been submitted! Thank you!
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ThankYouPage;
