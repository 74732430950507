import React, {useState} from "react";
import {Button, Stack, Typography,} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {ShareQrCodeModal} from "../../../components/survey-qrcode/ShareQrCodeModal";
import {blue} from "@mui/material/colors";
import {Download, QrCode2, QuestionAnswerOutlined} from "@mui/icons-material";

const primaryStyle = {
  bgcolor: blue[50],
  color: blue[300],
  borderRadius: 2,
  border: 1, px: 2, py: 1,
  borderColor: blue[100],
  width: "auto !important",
  minWidth: "0 !important",
  display: "flex",
  flexDirection: "row",
  gap: 1,
  "&:hover": {
    bgcolor: blue[100],
    color: blue[400],
  },
};

export const Actions = ({ downloadAnswers, activeSurvey }) => {
  const [openSurveyQrCode, setOpenSurveyQrCode] = useState(false);

  return (
    <>
      <Box>
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
        >
          <Button
            disabled={!activeSurvey}
            sx={{...primaryStyle}}
            survey-uid={activeSurvey?.uid}
            component={Link}
            to={`/analysis/${activeSurvey?.uid}`}
            // onClick={() => navigate('/analysis')}
          >
            <QuestionAnswerOutlined />
            <Typography sx={{color: "inherit", fontSize: "inherit", display: {xs: "none", md: "flex"} }}>View Responses</Typography>
          </Button>
          <Button
            disabled={!activeSurvey}
            sx={{...primaryStyle}}
            survey-uid={activeSurvey?.uid}
            onClick={() => setOpenSurveyQrCode(true)}
          >
            <QrCode2 />
            <Typography sx={{color: "inherit", fontSize: "inherit", display: {xs: "none", md: "flex"} }}>Generate QR & URL</Typography>
          </Button>
          <Button
            disabled={!activeSurvey}
            sx={{...primaryStyle}}
            survey-uid={activeSurvey?.uid}
            onClick={() => downloadAnswers()}
          >
            <Download />
            {/*<GetAppIcon/>*/}
            <Typography sx={{color: "inherit", fontSize: "inherit", display: {xs: "none", md: "flex"} }}>download responses</Typography>
          </Button>
        </Stack>
      </Box>

      <ShareQrCodeModal
        open={!!openSurveyQrCode}
        handleClose={() => setOpenSurveyQrCode(false)}
        urlToDisplay={`${window.location.origin}/survey/${activeSurvey?.uid}`}
        title={activeSurvey?.name}
        subtitle={"By sharing this QR code, you are sharing the survey with the public."}
      />
    </>
  );
};
