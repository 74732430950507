import React, { useState, useRef, useEffect } from 'react';
import { Slider, Typography, Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

const CustomAudioPlayer = ({ audioUrl }) => {
  const audioRef = useRef(new Audio(audioUrl)); // Reference to the audio element
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [currentTime, setCurrentTime] = useState(0); // Current time of the audio in seconds
  const [duration, setDuration] = useState(0); // Duration of the audio in seconds

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    // Load audio and update duration
    const handleLoadedMetadata = () => setDuration(audio.duration);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    // Update currentTime as audio plays
    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    audio.addEventListener('timeupdate', handleTimeUpdate);

    // Cleanup event listeners on unmount
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioRef.current]);

  // Play or pause audio
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle slider change to seek audio
  const handleSliderChange = (e, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  // Jump forward or backward by a specific amount of seconds
  const skipTime = (amount) => {
    const newTime = Math.min(Math.max(0, currentTime + amount), duration);
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleDuration = (event) => {
    console.log("Handle Load", event)
    if (event?.target?.duration === Infinity) {
      event.target.currentTime = (10000 * 312)
    }
  }

  return (
    <Box sx={styles.container}>
      <audio
        ref={audioRef} src={audioUrl}
        onLoadedMetadata={handleDuration}
        onDurationChange={(event) => {
          setDuration(event?.target?.duration);
          event.target.currentTime = 0;
        }}
        onEnded={(event) => event.target.currentTime = 0}
      />
      <IconButton onClick={() => skipTime(-10)} sx={styles.iconButton}>
        <SkipPreviousIcon />
      </IconButton>
      <IconButton onClick={togglePlayPause} sx={styles.iconButton}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <IconButton onClick={() => skipTime(10)} sx={styles.iconButton}>
        <SkipNextIcon />
      </IconButton>
      <Slider
        value={currentTime}
        max={duration}
        onChange={handleSliderChange}
        sx={styles.slider}
      />
      <Typography variant="body2" sx={styles.time}>
        {formatTime(currentTime)} / {formatTime(duration)}
      </Typography>
    </Box>
  );
};

// Format time in mm:ss
const formatTime = (time) => {
  if (time === Infinity) {
    return "0:00"
  }
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

// Styles for the player components
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '100%',
    maxWidth: '600px',
    px: 1, py: 0.5,
    borderRadius: '8px',
    backgroundColor: '#f5f5f5',
  },
  iconButton: {
    fontSize: '24px',
  },
  slider: {
    flexGrow: 1,
    mx: 2,
  },
  time: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
};

export default CustomAudioPlayer;
