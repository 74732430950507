import {Box, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import PropTypes from "prop-types";
import {AppContext} from "../../utils/AppContext";
import {useLocation, useNavigate} from "react-router-dom";
import {TotalParticipantChart} from "../project-summaries-page/components/TotalParticipantChart";
import {countDistinctObjectsByDay, getQuestionsFromDB,} from "../../utils/sharedFunctions";
import Grid from "@mui/material/Grid";
import {auth} from "../../utils/firebaseApp";
import SurveyList from "../../components/shared-components/SurveyList";

export const DashboardPage = () => {
  const {
    state: {answerObjects, surveys},
  } = useContext(AppContext);

  const [viewAllProjects, setViewAllProjects] = useState(false);


  return (
    <>
      <Stack
        maxWidth={"lg"}
        direction={"column"} gap={3}
        sx={{pb: "100px", width: "100%", mx: {xs: 2, md: "auto"}}}
      >
        <Box sx={{pb: 5}}>
          <TitleDate title="Snapshot" date={Date.now()} big={true}/>
          <Typography variant={"h4"} sx={{py: 2}}>
            Total Participants Over Time
          </Typography>
          <TotalParticipantChart
            xAxisLabels={Object.keys(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
            xAxisData={Object.values(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
          />
        </Box>
        <Box>
          <SurveyList
            surveys={surveys}
            viewAllProjects={viewAllProjects}
            setViewAllProjects={setViewAllProjects}
          />
        </Box>

      </Stack>
    </>
  );
};

DashboardPage.propTypes = {
  projectsList: PropTypes.any,
};
