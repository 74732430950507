import Modal from "@mui/material/Modal";
import {List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "95%",
  borderRadius: "16px",
  bgcolor: "background.paper",
  p: 4,
  "&:focus-visible": {border: "0 !important", outline: "none"},
  "&:focus": {
    border: "0px !important",
    outline: "none"
  }
};

export const SelectLanguage = ({languages, selectedLanguage, onSelect}) => {

  return (
    <Modal
      open={languages?.length > 0 && !selectedLanguage}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "&:focus": {
          outline: "none"
        }
      }}
      BackdropProps={{
        sx: {backdropFilter: "blur(8px)"}
      }}
    >
      <Stack direction={"column"} gap={2} sx={style}>
        <Typography variant={"h4"} fontWeight={600}>Select Language</Typography>
        <List sx={{maxHeight: "40dvh", overflowY: "auto"}}>
          {
            languages?.map(language => (
              <ListItemButton
                key={language?.BCP47}
                sx={{
                  my: 0.5, px: 1, borderRadius: 5, border: 0, bgcolor: "transparent",
                  '&:hover': {
                    bgcolor: grey[100]
                  }
              }}
                onClick={() => onSelect(language?.BCP47)}
              >
                <ListItemIcon>
                  <Checkbox checked={language?.BCP47 === selectedLanguage} color={"success"} />
                </ListItemIcon>
                <ListItemText primary={language?.Native} />
              </ListItemButton>
            ))
          }
        </List>
      </Stack>
    </Modal>
  )
}