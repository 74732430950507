import {Button, Box, Stack} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import React from "react";
import {useSetAtom} from "jotai/index";
import {CreateQuestionModalState} from "../atoms";

export const CreateQuestionButton = () => {
  const setCreateQuestion = useSetAtom(CreateQuestionModalState);

  const stylesHovered = {
    minWidth: 50, height: 50, mt: 2,
    p: 2, borderRadius: "56px !important",
    borderBottomRightRadius: "16px !important",
  }

  return (
    <Stack direction={"row"} justifyContent={"flex-end"}>
      <Box>
        <Button
          sx={stylesHovered}
          onClick={() => setCreateQuestion(true)}
          variant="extended"
          size="medium"
          startIcon={<IconPlus sx={{mr: 1}}/>}
          className={"gradient-btn"}
        >
          Add Question
        </Button>
      </Box>
    </Stack>

  )
}