import React, {useMemo} from 'react';
import {LongSelect} from "./form-components/long-select/long-select";
import {enums} from "../../utils/enums";

const SurveySelectorDropdowns = ({
                                     surveys,
                                     questionObjects,
                                     setActiveSurvey,
                                     setActiveQuestion,
                                     activeSurvey,
                                     activeQuestion,
                                     showQuestionSelect = false,
                                 }) => {
        function deduceActiveSurvey(uid) {
            const activeSurvey = surveys.find(survey => survey.uid === uid) || null
            setActiveSurvey(activeSurvey)
        }

        function deduceActiveQuestion(uid) {
            const activeQuestion = questionObjects.find(question => question.uid === uid) || null;
            setActiveQuestion(activeQuestion)
        }

        const menuItems = useMemo(() => {
          return questionObjects
            ?.filter(question => question.surveyUid === activeSurvey.uid)
            ?.sort((a, b) => a?.orderNumber - b?.orderNumber)
            ?.map((question) => {
              return {
                name: question.question,
                value: question.uid
              }
            }) || []
        }, [questionObjects, activeSurvey])

        const surveyItems = useMemo(() => {
            return surveys?.map(item => ({name: item?.surveyName, value: item?.uid})) || []
        }, [surveys])

        return (
            <>
                <LongSelect
                    id='analysis-select-survey'
                    title="Select Survey"
                    labelId='select-survey-label'
                    label='Select Survey'
                    variant={"outlined"}
                    menuItems={surveyItems}
                    selectedOption={activeSurvey?.uid}
                    onChange={deduceActiveSurvey}
                    setSelectedOption={deduceActiveSurvey}
                    size={"large"}
                />

                {showQuestionSelect && activeSurvey &&
                    <LongSelect
                        id='analysis-select-question'
                        title="Select Question"
                        labelId='select-question-label'
                        label='Select Question'
                        variant={"outlined"}
                        selectedOption={activeQuestion?.uid}
                        menuItems={menuItems}
                        setSelectedOption={deduceActiveQuestion}
                        size={"large"}
                    />}
            </>

        );
    }
;

export default SurveySelectorDropdowns;

