import React, {useState} from 'react';
import {Input, TextField, Typography} from '@mui/material';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const EditableTextField = ({initialText, onSave, type}) => {
    const [text, setText] = useState(initialText);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = () => {
        if (text !== initialText) {
            onSave(text, type);
        }
        setIsEditing(false);
    };

    return (
      <Input
        value={text}
        fullWidth disableUnderline={!isEditing}
        readOnly={!isEditing}
        multiline maxRows={3}
        onDoubleClick={() => setIsEditing(prev => !prev)}
        onChange={(e) => setText(e.target.value)}
        onBlur={handleSave}
        helperText={isEditing ? "Click outside to save" : "Double click to edit"}
        placeholder={type}
      />
    );
};


EditableTextField.propTypes = {
    initialText: PropTypes.string,
    onSave: PropTypes.func,
    type: PropTypes.string,
}
