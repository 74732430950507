import React from "react";
import {Button, Stack, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import Box from "@mui/material/Box";

export const SwitchButtons = ({title, firstType, secondType, selectedType, onChange}) => {
    return (
        <Box sx={{display: "block"}}>
            {title && <Typography variant={'body1'} sx={{p: "4px",}}>{title}</Typography>}
            <Stack
                gap={0.5}
                direction={"row"}
                sx={{borderRadius: 5, bgcolor: grey[200], p: "4px", float: "left"}}
            >
                <Button
                    size={"small"}
                    sx={selectedType === firstType ? selectedButton : {color: grey[500], borderRadius: 5, px: 1.5}}
                    onClick={() => onChange(firstType)}
                >
                    {firstType}
                </Button>
                <Button
                    size={"small"}
                    sx={selectedType === secondType ? selectedButton : {color: grey[500], borderRadius: 5, px: 1.5}}
                    onClick={() => onChange(secondType)}
                >
                    {secondType}
                </Button>
            </Stack>
        </Box>

    )
}

const selectedButton = {
    bgcolor: blue[300],
    color: "#FFF",
    borderRadius: 5,
    px: 1.5,
    "&:hover": {
        color: blue[300]
    }
}
