import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../utils/AppContext";
import {Button, Divider, IconButton, MenuItem, Modal, Select, Stack, Typography} from "@mui/material";
import {IconPlus, IconX} from "@tabler/icons-react";
import {QuestionTypes} from "../create-project-page-new/enums";
import Box from "@mui/material/Box";
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import * as React from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "45dvw", width: '90%',
  maxHeight: "90dvh", minHeight: "45dvh",
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 5
};

export const AddQuestionInReport = ({open, handleClose, reportQuestions, activeSurvey, onAddQuestion}) => {
  const {
    state: {questionObjects},
  } = useContext(AppContext);

  const [uniqueQuestions, setUniqueQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (open) {
      const reportQuestionsUid = reportQuestions?.map(i => i?.uid);

      let allQuestionObjectsForSurvey = questionObjects.filter(
        (question) => question?.surveyUid === activeSurvey?.uid,
      );
      let uniqueQuestionObjectsForSurvey = allQuestionObjectsForSurvey
        .filter((question) => question?.languageObject.BCP47 === activeSurvey?.languages[0].BCP47)
        .filter(question => question?.type !== QuestionTypes.SECTION)
        .filter(question => !reportQuestionsUid.includes(question.uid));
      setUniqueQuestions(uniqueQuestionObjectsForSurvey);
      setSelectedQuestion(uniqueQuestionObjectsForSurvey?.length ? uniqueQuestionObjectsForSurvey[0] : null);
      console.log(uniqueQuestionObjectsForSurvey)
    }
  }, [reportQuestions, questionObjects, activeSurvey, open]);

  const handleAddQuestion = () => {
    if (selectedQuestion) {
      onAddQuestion({...selectedQuestion, position: {i: selectedQuestion?.uid, x: 0, y: 0, w: 12, h: 8}});
      setUniqueQuestions([]);
      setSelectedQuestion(null);
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack direction={"column"} gap={"10px"} sx={style}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"center"}>
          <Typography variant={"h4"}>Add Question</Typography>
          <IconButton onClick={handleClose}><IconX/></IconButton>
        </Stack>
        <Divider sx={{width: "90%", mx: "auto"}}/>
        <Stack direction={"column"} gap={"10px"} sx={{flex: 1, overflowY: "auto"}}>

          <Box fullWidth>
            <Typography>Select Question</Typography>
            <Select
              value={selectedQuestion?.uid} fullWidth
              onChange={(e) => setSelectedQuestion(e.target.value)}
              disabled={uniqueQuestions?.length === 0}
            >
              {uniqueQuestions?.map(item => (
                <MenuItem key={item?.uid} value={item?.uid}>{item?.question}</MenuItem>
              ))}
            </Select>
          </Box>
          {uniqueQuestions?.length === 0 && <Typography variant={"subtitle2"} fontWeight={500}>There are no questions in this survey to be included in the report.</Typography>}
          <QuestionGraphWrapper
            question={selectedQuestion}
            index={1}
          />
        </Stack>
        <Divider sx={{width: "90%", mx: "auto", mt: "auto"}}/>
        <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
          <Button className={"gradient-outlined-btn"} sx={{border: 1, px: 2,}} onClick={() => handleClose()}>
            Close
          </Button>

          <Button className={"gradient-btn"} sx={{px: 2, width: "auto"}} startIcon={<IconPlus/>}
                  disable={!selectedQuestion}
                  onClick={() => handleAddQuestion()}>
            Add
          </Button>
        </Stack>

      </Stack>
    </Modal>
  )
}