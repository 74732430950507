import React, {useState} from "react";
import {Avatar, Card, CardContent, CardHeader, Fade, IconButton, Stack} from "@mui/material";
import {grey} from "@mui/material/colors";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";

export const CardItem = ({ title, avatar, subheader, secondary, children, collapsable = false }) => {
    const [isOpen, setIsOpen] = useState(true);

    const onCollapse = () => {
      if (collapsable) {
        setIsOpen(!isOpen)
      }
    }

    return (
        <Card sx={{
            borderRadius: 2,
            border: 1,
            borderColor: grey[200],
            bgcolor: "#FFF",
            boxShadow: 5,
            height: "100%"
        }}>
            <CardHeader
                sx={{ p: 1, py: 1.5, borderBottom: isOpen ? 1 : 0, borderColor: "divider", cursor: collapsable ? "pointer" : "inherit" }}
                onClick={onCollapse}
                avatar={
                  <Stack direction={"row"} gap={0} alignItems={"center"}>
                    {collapsable ?
                      <Avatar aria-label="settings" sx={{bgcolor: "transparent", color: "#888"}}>
                        <KeyboardArrowDownOutlined />
                      </Avatar> :
                      avatar
                    }
                  </Stack>
                }
                title={title}
                subheader={subheader}
                action={
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        {secondary}
                    </Stack>
                }
            />

            {
                isOpen ?
                    <Fade in={isOpen || !collapsable} timeout={500}>
                        <CardContent sx={{ p: 2, height: "100%" }}>
                            {children}
                        </CardContent>
                    </Fade> : <></>
            }

        </Card>
    )
}