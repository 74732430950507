import {Box, ListItemIcon, ListItemText, MenuItem, Select, Typography} from "@mui/material";
import React from "react";
import {questionItems} from "../../../utils/question-items";


export const SelectQuestionType = ({type, setType}) => {

  return (
    <Box>
      <Typography gutterBottom fontWeight={500}>Type</Typography>
      <Select
        value={type} fullWidth
        onChange={(e) => setType(e?.target?.value)}
        inputProps={{
          sx: {display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}
        }}
      >
        {Object.values(questionItems)?.map((option, index) => {
          const Icon = option.icon
          return  (
            <MenuItem
              key={`outlined-select-option-${option.value}`}
              value={option.value}
              sx={{display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}
            >
              <ListItemIcon sx={{minWidth: 0}}><Icon /></ListItemIcon>
              <ListItemText primary={option.name} />
            </MenuItem>
          )
        })}
      </Select>

    </Box>
  )
}