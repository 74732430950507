import React, {useEffect, useMemo, useState} from "react";
import {
  Typography,
  Modal,
  TextField,
  Button, Chip,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {toast} from "react-toastify";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {v4 as uuid} from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "95%",
  borderRadius: "16px",
  bgcolor: "background.paper",
  p: 4,
};

export const AddTokensModal = ({open, handleClose, survey}) => {
  const [numberOfLinks, setNumberOfLinks] = useState(10);

  useEffect(() => {
    if (open) {
      setNumberOfLinks(10);
    }
  }, [open])

  async function generateUniqueLinksAndDownloadCsv() {
    if (!survey?.uid) {
      toast.error("Please select a survey")
      return;
    }
    // const survey = surveys.find((s) => s.uid === activeSurvey?.uid);
    // if (!survey) return;
    toast.info('Generating...')
    try {
      const batch = writeBatch(db);

      for (let i = 0; i < numberOfLinks; i++) {
        const token = uuid()
        batch.set(doc(db, "tokens", token), {
          surveyUid: survey?.uid,
          token: token,
          used: false,
          dateCreated: new Date().getTime()
        });

      }
      await batch.commit();
      toast.success("Tokens generated successfully");
    } catch (e) {
      console.log(e)
    } finally {
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} direction={"column"} gap={2}>
        <Typography variant={"h4"}>Generate Tokens</Typography>
        <TextField
          value={numberOfLinks} variant={"outlined"}
          onChange={(e) => setNumberOfLinks(e?.target?.value ? +e?.target?.value : "")}
          label={"Number of Tokens"}
        />

        <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
          <Chip color={numberOfLinks === 5 ? "success" : "default"} sx={{px: 1}} label={5} onClick={() => setNumberOfLinks(5)}/>
          <Chip color={numberOfLinks === 10 ? "success" : "default"} sx={{px: 1}} label={10} onClick={() => setNumberOfLinks(10)}/>
          <Chip color={numberOfLinks === 20 ? "success" : "default"} sx={{px: 1}} label={20} onClick={() => setNumberOfLinks(20)}/>
          <Chip color={numberOfLinks === 50 ? "success" : "default"} sx={{px: 1}} label={50} onClick={() => setNumberOfLinks(50)}/>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={handleClose}
            sx={{borderRadius: 2, flex: 1}}
          >
            Close
          </Button>
          <Button
            className={"gradient-btn"}
            onClick={generateUniqueLinksAndDownloadCsv}
            sx={{
              color: "white", flex: 1
            }}
          >
            Generate
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
