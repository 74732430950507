import React from 'react';
import {Typography} from "@mui/material";

const QuestionTitle = ({title}) => {
  return (
    <Typography variant={title?.length < 30 ? "h3" : title?.length < 60 ? "h4" : "h5"} color={"secondary"} fontWeight={500}>{title}</Typography>
  );
};

export default QuestionTitle;

