import { ContentCopy, DeleteOutline, Edit, Input, RestartAlt } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useState } from "react";
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { auth, db, functions } from "../../../utils/firebaseApp";
import { createTranslationQuestion } from "../../../utils/functions";
import { questionItems } from "../../../utils/question-items";
import {
  CreateQuestionModalState,
  CreateSurveyQuestionsState,
  CreateSurveyState,
  EditQuestionModalState, SelectedLanguageState
} from "../atoms";
import { QuestionStatusType } from "../enums";


export const QuestionItem = ({question}) => {
  const setEditQuestion = useSetAtom(EditQuestionModalState);
  const setCreateQuestion = useSetAtom(CreateQuestionModalState);
  const questions = useAtomValue(CreateSurveyQuestionsState);
  const selectedLanguage = useAtomValue(SelectedLanguageState);
  const survey = useAtomValue(CreateSurveyState)
  const [loading, setLoading] = useState(false);
  const startTranslation = httpsCallable(functions, "translateFromSourceToTarget");

  const duplicateQuestion = async () => {
    const orderNumber = questions?.filter(i => i?.languageObject?.BCP47 === selectedLanguage?.BCP47)?.length || 0;
    const questionObj = {...question};

    questionObj.uid = uuid();
    questionObj.userUid = auth?.currentUser?.uid;
    questionObj.dateCreated = new Date().getTime();
    questionObj.dateUpdated = new Date().getTime();
    questionObj.orderNumber = orderNumber;
    questionObj.status = QuestionStatusType.TRANSLATED;
    questionObj.originalReferenceUid = null;
    const batch = writeBatch(db);
    batch.set(doc(db, "questions", questionObj.uid), questionObj);

    const translateQuestions = questions
      ?.filter(i => i?.originalReferenceUid === question.uid || (question?.originalReferenceUid && (i?.uid === question?.originalReferenceUid || i?.originalReferenceUid === question?.originalReferenceUid)))
      ?.filter(i => i?.uid !== question?.uid && i?.uid !== questionObj?.uid) || []
    console.log(translateQuestions);
    if (translateQuestions.length === 0) {
      for (const language of survey?.languages || []) {
        if (language && language?.BCP47 !== selectedLanguage?.BCP47) {
          const translateQuestion = createTranslationQuestion({question: questionObj, language});
          translateQuestion.status = QuestionStatusType.TRANSLATING;
          translateQuestion.orderNumber = orderNumber;
          translateQuestion.originalReferenceUid = questionObj?.uid;
          translateQuestion.userUid = auth?.currentUser?.uid;
          questionObj.dateCreated = new Date().getTime();
          questionObj.dateUpdated = new Date().getTime();
          batch.set(doc(db, "questions", translateQuestion.uid), translateQuestion);
          translateQuestions.push(translateQuestion);
        }
      }
    } else {
      for (const index in translateQuestions || []) {
        const questionItem = translateQuestions[index];
        console.log("Create copy of translation", questionItem)
        if (questionItem !== question?.uid) {
          const translateQuestion = createTranslationQuestion({
            question: questionItem,
            language: questionItem?.languageObject
          });
          translateQuestion.status = QuestionStatusType.TRANSLATED;
          translateQuestion.orderNumber = orderNumber;
          translateQuestion.originalReferenceUid = questionObj?.uid;
          translateQuestion.userUid = auth?.currentUser?.uid;
          questionObj.dateCreated = new Date().getTime();
          questionObj.dateUpdated = new Date().getTime();

          batch.set(doc(db, "questions", translateQuestion.uid), translateQuestion);
          translateQuestions[index] = translateQuestion;
        }
      }
    }
    const toastId = toast.loading("Creating your question...");
    try {
      setLoading(true);
      await batch.commit();

      if (translateQuestions?.filter(i => i?.status === QuestionStatusType.FAILED)?.length) {
        const response = await confirm(
          {
            title: `Do you want to translate question in other languages?`,
            confirmText: "Yes",
            cancelText: "No",
            // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
          },
          confirmDialogOptions,
        );
        if (response) {
          for (const questionItem of translateQuestions?.filter(i => i?.status === QuestionStatusType.FAILED) || []) {
            if (!questionItem?.uid || !questionItem?.languageObject?.Native) {
              continue;
            }
            try {
              await startTranslation({sourceUid: questionObj?.uid, targetUid: questionItem?.uid})
              toast.update(toastId, {
                render: `Translation started: ${questionItem?.languageObject?.Native} `,
                type: "info",
                isLoading: true
              });
            } catch (error) {
              toast.update(toastId, {
                render: `Failed to translate: ${questionItem?.languageObject?.Native} `,
                type: "error",
                isLoading: true
              });
              await updateDoc(doc(db, "questions", questionItem.uid), {status: QuestionStatusType.FAILED});
            }
          }
        }
      }

      toast.update(toastId, {
        render: `Question added successfully`,
        autoClose: 3000,
        type: "success",
        isLoading: false
      });
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: `Failed to duplicate question..`,
        autoClose: 3000,
        type: "error",
        isLoading: false
      });
    } finally {
      setLoading(false);
    }
  }

  const deleteQuestion = async () => {
    const response = await confirm(
      {
        title: `Are you sure you want to delete this question?`,
        subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
      },
      confirmDialogOptions,
    );
    if (response) {
      const deleteItems = questions?.filter(i => i?.uid === question.uid || i?.originalReferenceUid === question.uid || (question?.originalReferenceUid && (i?.uid === question?.originalReferenceUid || i?.originalReferenceUid === question?.originalReferenceUid))) || [];
      const batch = writeBatch(db);
      for (const item of deleteItems || []) {
        batch.delete(doc(db, "questions", item.uid));
      }
      try {
        setLoading(true);
        await batch.commit();
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete question")
      } finally {
        setLoading(false);
      }
    }
  }

  const onTranslateAgain = async () => {
    const originalQuestion = questions?.find(i => i?.uid === question?.originalReferenceUid) || null;

    if (originalQuestion) {
      const toastId = toast.loading("Translation started...");
      try {
        setLoading(true);

        await startTranslation({sourceUid: originalQuestion?.uid, targetUid: question?.uid})
        toast.update(toastId, {
          render: `Translation Finished: ${question?.languageObject?.Native} `,
          type: "success", autoClose: 3000,
          isLoading: false
        });
      } catch (error) {
        toast.update(toastId, {
          render: `Failed to translate: ${question?.languageObject?.Native} `,
          type: "error", autoClose: 3000,
          isLoading: false
        });
        await updateDoc(doc(db, "questions", question.uid), {status: QuestionStatusType.FAILED});
      } finally {
        setLoading(false);
      }
    }
  }

  const Icon = questionItems[question.type]?.icon || Input;

  return (
    <Box
      sx={{flex: 1, px: {xs: 2, md: 3, lg: 4}, py: {xs: 2, md: 3}, borderRadius: 5, bgcolor: "#fff"}}
      className={`question border-shadow ${question?.status === QuestionStatusType.FAILED ? "error" : ""}`}
    >
      <Stack direction={"row"} gap={2} justifyContent={"space-between"} useFlexGap flexWrap={"wrap"}>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Icon fontSize={"small"}/>
          <Typography fontWeight={400} fontSize={"small"}
                      textTransform={"capitalize"}>{question?.type?.replaceAll("_", " ")} - {question?.languageObject?.Native}</Typography>

        </Stack>
        <Stack direction={"row"} gap={1}>
          <Tooltip title={"Translate again"}>
            <IconButton
              size={"small"}
              disabled={loading || !question?.originalReferenceUid}
              onClick={onTranslateAgain}
            >
              <RestartAlt />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Edit Question"}>
            <IconButton
              size={"small"}
              disabled={loading}
              onClick={() => {
                setCreateQuestion(true);
                setEditQuestion(question);
              }}
            >
              <Edit fontSize={"small"}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={"Duplicate this question"}>
            <IconButton
              size={"small"}
              disabled={loading}
              onClick={duplicateQuestion}
            >
              <ContentCopy fontSize={"small"}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete Question"}>
            <IconButton
              size={"small"}
              disabled={loading}
              onClick={deleteQuestion}
            >
              <DeleteOutline fontSize={"medium"}/>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={2} sx={{py: 1}}>
        <Typography variant={"h6"} fontWeight={400} color={"primary"}>{question?.orderNumber + 1}</Typography>
        <Typography variant={"h6"} fontWeight={400}>{question?.question}</Typography>
      </Stack>
      {
        {
          [QuestionStatusType.FAILED]: <Typography fontWeight={400} fontSize={"small"} textTransform={"capitalize"}>Translation
            failed...</Typography>,
          [QuestionStatusType.TRANSLATING]: <Typography fontWeight={400} fontSize={"small"}
                                                        textTransform={"capitalize"}>Translating in
            progress...</Typography>,
        }[question?.status]
      }
    </Box>
  )
}
