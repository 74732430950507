import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {InputWithLabel} from "../create-question-modal";
import React, {useMemo} from "react";
import {grey} from "@mui/material/colors";
import {QuestionTypes} from "../../enums";


export const SectionQuestion = ({question, onChange, onUpdate, image, setImage}) => {

  const imgUrl = useMemo(() => {
    if (image) {
      return URL.createObjectURL(image)
    }
    return question?.imgUrl || null;
  }, [image, question?.imgUrl])


  return (
    <>
      <Typography fontWeight={500} sx={{borderBottom: 1, borderColor: "divider", pb: 0.5, pt: 1}}>Optional
        items</Typography>
      {
        question?.type === QuestionTypes.SECTION &&
        <>
          {
            imgUrl &&
            <img src={imgUrl} width={"auto"} height={"200px"} alt={"Section"} style={{objectFit: "contain"}}/>
          }
          <Box sx={{textAlign: "center", py: 1}}>
            <Button variant={"outlined"} className={"gradient-outlined-btn"}>
              <label htmlFor={'upload-button'}>
                {/*<AddAPhoto/>*/}
                {!imgUrl ? "Upload image" : "Reupload"}
                <input type="file" accept="image/*" id="upload-button" style={{display: 'none'}}
                       onChange={(e) => setImage(e.target.files[0] || null)} key={'addImage'}/>
              </label>
            </Button>
            <Typography fontSize={"0.75rem"} color={grey[500]} sx={{pt: 1, width: "75%", mx: "auto"}} fontWeight={300}>
              We recommend an image {"<100kb"} so your survey loads fast. You can optimize your image on sites like
              &nbsp;
              <a href={"https://imagecompressor.com/"} target={"_blank"} style={{color: "inherit"}}>Optimizilla</a>
              &nbsp;or&nbsp;
              <a href={"https://squoosh.app/"} target={"_blank"} style={{color: "inherit"}}> Squoosh </a>.
            </Typography>
          </Box>
          <Divider/>
        </>
      }
      <InputWithLabel label={"Description"}>
        <TextField
          value={question?.subTitle} onChange={onChange} fullWidth multiline rows={2}
          name={"subTitle"} placeholder={"This is an optional subtitle that goes below the title"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Bottom Explainer"}>
        <TextField
          value={question?.bottomExplainer} onChange={onChange} fullWidth
          name={"bottomExplainer"} placeholder={"Small text blurb"}
          multiline minRows={1} maxRows={2}
        />
      </InputWithLabel>
      <InputWithLabel label={"Bottom Explainer URL"}>
        <TextField
          value={question?.link} onChange={onChange} fullWidth
          name={"link"}
          placeholder={"Paste a URL here to link your text to an external page."}
        />
      </InputWithLabel>
    </>
  )
}
