import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Avatar, Stack, Typography} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import {CardItem} from "../../../../components/card/CardItem";
import {blue} from "@mui/material/colors";
import {ColumnChart} from "./ColumnChart";


const GraphTypes = {RADIAL: "radial", BAR: "bar"}

export const MultipleChoiceGraph = ({question, answers, title, subtitle, index, secondaryAction, collapsable}) => {
    const [type, setType] = useState(GraphTypes.BAR);
    const [options, setOptions] = useState({});

    useEffect(() => {
        const newOptions = {};
        if (!answers) return;
        console.log(answers);
        console.log(question)
        for (const option of question?.options || []) {
            if (option?.option) {
                newOptions[option?.option] = 0;
            }
        }
        for (const answer of answers) {
            if (answer.type !== 'multichoice' && answer.type !== 'checkboxes' && answer.type !== 'dropdown') continue

            const option = (answer?.translation || answer?.text || "").toString();
            const options = Object.keys(newOptions);
            if (options.includes(option)) {
                newOptions[option] = newOptions[option] + 1;
            }
        }
        setOptions(newOptions)
    }, [answers])

    return (
        <CardItem
            collapsable={collapsable}
            title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>{title}</Typography>}
            secondary={secondaryAction || null}
            subheader={<Typography fontSize={12} variant={"secondary2"}>{subtitle}</Typography>}>
            <Stack sx={{flexDirection: {xs: "column", md: "row"}, my: 1, height: "100%"}} justifyContent={"center"}
                   alignItems={"start"}
                   gap={2}>
                {/*<Stack direction={"column"} gap={2} sx={{minWidth: "250px"}}>*/}
                {/*  <SwitchButtons*/}
                {/*    firstType={GraphTypes.BAR}*/}
                {/*    secondType={GraphTypes.RADIAL}*/}
                {/*    onChange={(newType) => setType(newType)}*/}
                {/*    selectedType={type}*/}
                {/*  />*/}
                {/*  <ExportQuestion*/}
                {/*    question={question}*/}
                {/*  />*/}
                {/*</Stack>*/}
                <Box sx={{flex: 1}}>
                    <RadialChart open={type === GraphTypes.RADIAL} options={options}/>
                    <ColumnChart open={type === GraphTypes.BAR} options={options}/>
                </Box>
            </Stack>
        </CardItem>
    )
}

const RadialChart = ({open, options}) => {
    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                height: 390,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    }
                }
            },
            colors: [],
            labels: [],
            legend: {
                show: true,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 160,
                offsetY: 15,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }]
        },
    });

    useEffect(() => {
        let max = 0;
        for (const value of Object.values(options)) {
            if (value > max) {
                max = value;
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                series: Object.values(options).map(value => (value / max) * 100),
                options: {
                    ...prevState.options,
                    labels: Object.keys(options),
                    colors: generateRandomColors(Object.keys(options).length),
                    legend: {
                        ...prevState.options.legend,
                        formatter: (seriesName, opts) => {
                            let value = opts.w.globals.series[opts.seriesIndex] || 0;
                            if (value > 0) {
                                value = (value * max) / 100
                            }
                            return seriesName + ":  " + value
                        }
                    }
                }
            }
        })
    }, [options])

    function getRandomRgb() {
        var num = Math.round(0xffffff * Math.random());
        var r = num >> 16;
        var g = num >> 8 & 255;
        var b = num & 255;
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }

    const generateRandomColors = (num) => {
        const colors = [];
        for (let i = 0; i < num; i++) {
            const color = getRandomRgb();
            colors.push(color)
        }
        return colors;
    }

    return (
        open && <Typography variant={'body1'}>Radials are coming soon...</Typography>
        // open && <ReactApexChart options={state.options} series={state.series} type="radialBar" height={390} />
    )
}

