import React from 'react';
import ChatWindow from "./ChatWindow";

const SurveyDesignerPage = () => {
    return (
        <>
            <ChatWindow/>
        </>
    );
};

export default SurveyDesignerPage;
