import {Button, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import React from "react";


export const CreateSurveyBanner = () => (
  <Stack direction={"column"} gap={2}>
    <Typography variant={"h5"} fontWeight={600} sx={{ marginTop: 5 }}>
      It looks like you haven’t created any surveys yet.
    </Typography>
    <Typography variant={"h5"} fontWeight={600}>
      Create your first survey using the button below! 🚀
    </Typography>
    <Box>
      <Button
        component={Link}
        to={"/create-survey"}
        sx={{
          mt: 2,
          padding: "6px 18px", width: "auto !important",
          display: {xs: "none", md: "inline-flex"},
        }}
        variant={"contained"}
        className={"gradient-btn"}
      >
        <AddIcon sx={{height: "18px"}}/>
        <Typography sx={{fontSize: "1em"}}>Create Survey</Typography>
      </Button>
    </Box>
  </Stack>
)