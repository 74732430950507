import React, { useEffect, useRef, useState } from 'react';
import { Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Map, Marker } from "react-map-gl";
import PulsingDot from "../../assets/PulsingDot/PulsingDot";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import "mapbox-gl/dist/mapbox-gl.css";
import { colors } from "./mapIcons";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import { IconCurrentLocation, IconLocation } from "@tabler/icons-react";

const MapComponent = ({ showUserDot, questionObject, userCanSetPOIs, pois, setPois }) =>
{
    const token = 'pk.eyJ1IjoidXJvc3JhbiIsImEiOiJjbGc0YzIzem0wbzdtM2VxZXF1cnphZ3VkIn0.enXGQJh4-YIVVndbjXsKyA'
    const [locateUserPressed, setLocateUserPressed] = useState(false);
    const [selectedChip, setSelectedChip] = useState(questionObject?.options?.length > 0 ? questionObject?.options[0]?.option : null)
    const [mapInteraction, setMapInteraction] = useState(false);
    const [viewport, setViewport] = useState({
        longitude: -74.0060,
        latitude: 40.7128,
        zoom: 8
    });
    const [userLocation, setUserLocation] = useState({
        longitude: -74.0060,
        latitude: 40.7128,
    })
    const mapRef = useRef();

    useEffect(() =>
    {
        const firstOption = questionObject?.options?.length > 0 ? questionObject?.options[0]?.option || null : null;
        const options = questionObject?.options?.map(i => i?.option) || [];
        if (!options.length || !options.includes(selectedChip))
        {
            setSelectedChip(firstOption)
        }
    }, [questionObject])

    useEffect(() =>
    {
        if (pois?.length === 0 || userCanSetPOIs)
        {
            // locateUser();
            return;
        }
        const midpoint = calculateMidpointFromJsonList(pois)
        //TODO: condition showUserDot used only because in display mode we don't want to fly to the user location
        mapRef.current?.flyTo({ center: [midpoint.lng, midpoint.lat], duration: 2000 });
    }, [pois, userCanSetPOIs])

    function locateUser()
    {
        if (!locateUserPressed)
        {

            navigator.geolocation.getCurrentPosition(function (position)
            {
                setViewport({ longitude: position.coords.longitude, latitude: position.coords.latitude, zoom: 10 })
                setUserLocation({ longitude: position.coords.longitude, latitude: position.coords.latitude })
                mapRef.current?.flyTo({ center: [position.coords.longitude, position.coords.latitude], duration: 2000 });
                setLocateUserPressed(true);
            }, (error) =>
            {
                console.log("Failed to get location", error)
            });
        }
    }

    function calculateMidpointFromJsonList(jsonList)
    {
        if (jsonList.length === 0)
        {
            return null; // No coordinates to calculate midpoint
        }

        // Calculate average latitude and longitude
        const sumLat = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lat, 0);
        const sumLng = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lng, 0);

        const avgLat = sumLat / jsonList.length;
        const avgLng = sumLng / jsonList.length;

        return { lat: avgLat, lng: avgLng };
    }

    function handleAddPOI(e)
    {
        let coordinates = e.lngLat
        let shouldAddPoi = true
        // setUserLocation(prev => ({...prev, latitude: coordinates?.lat, longitude: coordinates.lng}))
        pois.forEach(poi =>
        {
            if (poi.coordinates.lng === coordinates.lng && poi.coordinates.lat === coordinates.lat) shouldAddPoi = false
        })

        if (shouldAddPoi) setPois([...pois, { coordinates: coordinates, option: selectedChip }])

    }

    const handleMapClick = (e) =>
    {
        console.log(e)
        e?.originalEvent?.stopPropagation()
        if (userCanSetPOIs && !mapInteraction)
        {
            handleAddPOI(e);
        }
    }

    function removeMarker(coords)
    {
        const filteredPois = pois.filter(poi =>
        {
            return poi.coordinates.lng !== coords.lng && poi.coordinates.lat !== coords.lng
        })
        setPois([...filteredPois])
    }

    function CustomMarker(props)
    {
        const { longitude, latitude, icon, coords } = props;

        return (
            <Marker onClick={(e) =>
            {
                e.originalEvent.stopPropagation();
                removeMarker(coords)
            }} latitude={latitude} longitude={longitude} offsetLeft={-20} offsetTop={-10}>
                <div>{icon}</div>
            </Marker>
        );
    }


    function generateRandomColorHex()
    {
        // Generate a random number between 0 and 16777215 (FFFFFF in hexadecimal)
        const randomColor = Math.floor(Math.random() * 16777216);

        // Convert the random number to a hexadecimal string
        return '#' + randomColor.toString(16).padStart(6, '0');
    }

    function getindexByProperty(array, propertyValue, propertyToLookFor)
    {
        let index = -1
        array.map((opt, i) =>
        {
            if (opt[propertyToLookFor] === propertyValue) index = i
        })
        return index
    }

    const isIos = /iPhone|iPad|iPod/.test(navigator.userAgent);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start' }}>
            <Grid item xs={12}>
                <Map
                    mapboxApiAccessToken={token}
                    ref={mapRef}
                    mapboxAccessToken={token}
                    scrollZoom={true}
                    initialViewState={viewport}
                    style={{ width: "100%", height: 'auto', minHeight: '300px' }}
                    onZoomStart={() => setMapInteraction(true)}
                    onZoomEnd={() => setMapInteraction(false)}
                    onMoveStart={() => setMapInteraction(true)}
                    onMoveEnd={() => setMapInteraction(false)}
                    onClick={handleMapClick}
                    mapStyle={'mapbox://styles/urosran/clgxw85q1008801qt5gswf9la'}
                    attributionControl={false}
                    position={'top-right'}
                >
                    {pois?.map((poi) =>
                    {
                        return (
                            <CustomMarker key={JSON.stringify(poi)}
                                latitude={parseFloat(poi.coordinates.lat)}
                                longitude={parseFloat(poi.coordinates.lng)}
                                coords={poi.coordinates}
                                icon={<PulsingDot pulse={false}
                                    fill={questionObject?.options?.length > 0 ?
                                        colors[getindexByProperty(questionObject.options, poi.option, 'option')]
                                        : generateRandomColorHex()} />}
                                locationObject={poi}
                            />)
                    })}


                    {showUserDot && !mapInteraction ? <CustomMarker
                        latitude={parseFloat(userLocation.latitude)}
                        longitude={parseFloat(userLocation.longitude)}
                        name={"You Are Here"}
                        icon={<PulsingDot pulse={true} fill={'#0860b0'} />}
                        locationObject={null}
                    /> : null}
                    <IconButton
                        size={'large'}
                        sx={{
                            backgroundColor: '#fefefe !important',
                            backgroundImage: "blur(4px)",
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            zIndex: 10,
                            color: "info.dark",
                            "&:disabled": {
                                backgroundColor: "#f1f1f1"
                            },
                            "&:hover": {
                                scale: "1.05 !important",
                                boxShadow: 4
                            }
                        }}
                        classes={{ disabled: { backgroundColor: green[300] } }}
                        aria-label="add"
                        disabled={locateUserPressed}
                        onClick={(e) =>
                        {
                            e?.stopPropagation();
                            locateUser();
                        }}>
                        {isIos ? (
                            <IconLocation />
                        ) : (
                            <IconCurrentLocation />
                        )}
                    </IconButton>
                </Map>

                {userCanSetPOIs &&
                    <Stack direction={'row'} justifyContent={"center"}>
                        <InfoIcon sx={{ color: 'gray', marginRight: 0.5, fontSize: 12 }} />
                        <Typography sx={{ color: 'gray', fontSize: 12 }}>Click the item and touch the map where it is
                            located</Typography>
                    </Stack>}

                <Box sx={{ marginTop: 0.5 }}>
                    {questionObject.options?.map((option, index) =>
                    {
                        return <Chip label={option.option} disabled={false} name={option.option}
                            sx={{
                                backgroundColor: (selectedChip === option.option || !userCanSetPOIs) ? colors[index] : "gray",
                                margin: 1,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: selectedChip === option.option ? colors[index] : "gray"
                                }
                            }}
                            key={option.option}
                            clickable
                            onClick={() => setSelectedChip(option?.option)} />
                    })}
                </Box>
            </Grid>
        </Box>
    )
}


export default MapComponent;

MapComponent.propTypes = {
    pois: PropTypes.any,
    questionObject: PropTypes.any,
    setPois: PropTypes.any,
    userCanSetPOIs: PropTypes.any
}
