import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import {FormControlLabel, RadioGroup, TextField} from "@mui/material";
import React from "react";
import Radio from "@mui/material/Radio";


export const MultiChoiceQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  const onSelect = (option) => {
    setAnswer(prev => ({
      ...prev,
      text: option,
      options: prev?.options?.map(i => ({...i, checked: i?.option === option}))
    }))
  }

  return (
    <>
      <QuestionTitle title={answer?.question ? <>{answer?.question} {answer?.required ? <span style={{color: "red"}}>*</span> : null}</> : "-"} />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        {answer?.options?.map(item => {
          return (
            <FormControlLabel value={item?.option}
                              control={<Radio checked={item?.checked}/>}
                              checked={item?.checked}
                              label={item?.option}
                              key={item.option}
                              onChange={(e) => onSelect(item?.option)}/>
          )
        })}
      </RadioGroup>
    </>
  )
}