import React, { useMemo } from "react";
import {
  Typography,
  Box,
  Modal,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "95%",
  borderRadius: "16px",
  bgcolor: "background.paper",
  p: 4,
};

export const ShareQrCodeModal = ({ open, handleClose, urlToDisplay, title, subtitle }) => {

  const copyToClipboard = (url) => {
    try {
      navigator.clipboard.writeText(url);
      toast.success("URL copied successfully");
    } catch (error) {
      toast.success("Error copying URL");
    }
  };

  return (
    urlToDisplay &&
    (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style} direction={"column"} gap={2}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ alignSelf: "center" }}
            color={"secondary"}
          >
            {title}
          </Typography>
          <Typography variant={'body1'} sx={{textAlign: 'center'}}>
            {subtitle}
          </Typography>
          <Box sx={{ mx: "auto" }}>
            <QRCode title="Survey URL" value={urlToDisplay} size={150} />
          </Box>
          <TextField
            label={"URL"}
            type={"text"}
            value={urlToDisplay}
            fullWidth
            sx={{
              marginTop: "20px",
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction={"row"} gap={2} sx={{ px: 1 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => copyToClipboard(urlToDisplay)}
                      edge="end"
                    >
                      <ContentCopy />
                    </IconButton>
                  </Stack>
                </InputAdornment>
              ),
            }}
          />

          <Stack direction={"row"} gap={2}>
            <Button
              fullWidth
              variant={"outlined"}
              color={"secondary"}
              onClick={handleClose}
              sx={{borderRadius: 2}}
            >
              Close
            </Button>
            <Button
              className={"gradient-btn"}
              component={"a"}
              href={urlToDisplay}
              target={"_blank"}
              onClick={handleClose}
              fullWidth
              sx={{
                color: "white",
              }}
            >
              Open
            </Button>
          </Stack>
        </Stack>
      </Modal>
    )
  );
};
