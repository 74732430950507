import {Slider, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CardItem} from "../../../../components/card/CardItem";

export const SliderGraph = ({
                             question,
                             answers,
                             title,
                             subtitle,
                             secondaryAction,
                             collapsable
                           }) => {

  const [marks, setMarks] = useState([]);
  const [avg, setAvg] = useState(null);

  useEffect(() => {
    const marks = answers?.map(i => ({value: i?.text, label: <Tooltip title={i?.text}><Typography sx={{marginTop: "-30px", opacity: 0}}>|</Typography></Tooltip>}) || []);
    setMarks(marks);
    const avg = answers?.map(i => +i?.text)?.filter(i => !isNaN(i))?.reduce((prev, next) => prev + next, 0);
    setAvg(avg ? (avg / answers?.length) : 0);

    console.log(answers)
    console.log(marks)
    console.log(avg ? (avg / answers?.length) : 0)
  }, [answers])

  function valuetext(value) {
    return `${value}`;
  }


  return (
    <CardItem
      title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>{title}</Typography>}
      subheader={<Typography fontSize={12} variant={"secondary2"} color={"#858585"}>{subtitle}</Typography>}
      key={question.uid} collapsable={collapsable}
      secondary={secondaryAction || null}
    >
      <Slider
        aria-label="Always visible"
        value={avg}
        getAriaValueText={valuetext}
        step={10}
        min={question?.from ? +question?.from : 0}
        max={question?.to ? +question?.to : 100}
        marks={marks}
        sx={{cursor: "default"}}
        valueLabelDisplay="on"
      />
    </CardItem>
  )
}