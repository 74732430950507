import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import React, {useEffect, useState} from "react";
import {Button, Slide, Stack, Typography} from "@mui/material";
import {useAtom} from "jotai";
import {LocationOffOutlined, LocationOnOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";

export const GeoLocationQuestion = () => {
    const [answer, setAnswer] = useAtom(AnswerAtom);
    const [locationAcquired, setLocationAcquired] = useState(null)

    function geolocateUser() {
        navigator.geolocation.getCurrentPosition(function (position) {
                setAnswer(prev => {
                    const answerObject = {...prev};
                    answerObject.lng = position.coords.longitude
                    answerObject.lat = position.coords.latitude
                    answerObject.text = position.coords.latitude + "," + position.coords.longitude
                    return {...answerObject};
                })
                toast.success('Location acquired')
                setLocationAcquired(true)

            },
            function (positionError) {
                if (positionError?.code === 1) {
                    toast.error("Permission denied");
                } else if (positionError?.code === 2) {
                    toast.error("The acquisition of the geolocation failed because at least one internal source of position returned an internal error.");
                } else if (positionError?.code === 3) {
                    toast.error("The acquisition of the geolocation failed because at least one internal source of position returned an internal error.");
                } else {
                    toast.error("Failed to get location")
                }
                setLocationAcquired(false);
                console.log(positionError);
            });
    }

    return (
        <>
            <QuestionTitle title={answer?.question ? <>{answer?.question} {answer?.required ? <span style={{color: "red"}}>*</span> : null}</> : "-"} />
            <Typography align={"center"} fontWeight={500} fontSize={"1.1rem"}>{answer?.subTitle}</Typography>
            <Slide direction={"right"} in={!!answer?.imgUrl} timeout={1000}>
                <Box sx={{maxHeight: "45dvh", textAlign: 'center'}}>
                    <img src={answer?.imgUrl} alt={answer?.title} width={"100%"} height={"auto"}
                         style={{objectFit: "contain", maxHeight: "45dvh"}}/>
                </Box>
            </Slide>

            {locationAcquired && <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <LocationOnOutlined color={"success"}/>
                <Typography>Location Acquired Successfully</Typography>
            </Stack>}

            {!locationAcquired &&
                <Button startIcon={<LocationOnOutlined/>}
                        variant={"outlined"}
                        onClick={geolocateUser}>Acquire location</Button>}

            <Typography
              component={answer?.link ? "a" : "p"}
              href={answer?.link || undefined}
              target={answer?.link ? "_blank" : undefined}
              align={"center"} fontWeight={500} fontSize={"0.9rem"}
            >
              {answer?.bottomExplainer}
            </Typography>
        </>
    )
}
