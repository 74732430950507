import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import chartIcon from "../../../../assets/icons/chart.png";
import {AppContext} from "../../../../utils/AppContext";
import {BarChart} from "@mui/icons-material";

export const ViewSummaryButton = ({surveyUid}) => {

    return (
        <Button
            size={"large"}
            variant={"contained"}
            fullWidth
            className={"gradient-btn"}
            component={Link}
            startIcon={<BarChart/>}
            sx={{py: 1.5, mt: 1}}
            to={`/summaries/${surveyUid}`}
        >
          View Survey Summary
        </Button>
    );
};
