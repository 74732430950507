import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../utils/AppContext";
import {VoiceQuestionGraph} from "./VoiceQuestionGraph";
import {Stack, Typography} from "@mui/material";
import {ImageQuestionPreview} from "./ImageQuestionPreview";
import {MultipleChoiceGraph} from "./MultipleChoiceGraph";
import {MapOfResponses} from "../../../project-summaries-page/components/MapOfResponses";
import {QuestionTypes} from "../../../create-project-page-new/enums";
import {SliderGraph} from "./SliderGraph";

export const QuestionGraphWrapper = ({question, index, setActiveQuestion, removeFromReport, collapsable}) => {
    const {state: {answerObjects}} = useContext(AppContext);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        let questionAnswers = [];
        if (question) {
            const qUid = question?.originalReferenceUid || question?.uid;
            const filteredQuestionAnswers = answerObjects?.filter(answer => (answer?.questionUid === qUid) || (answer?.originalReferenceUid === qUid))
            questionAnswers = [...questionAnswers, ...filteredQuestionAnswers]
        }
        setAnswers(questionAnswers);
    }, [question, answerObjects]);

    const GraphNotFound = <Stack direction={"column"} height={'390px'}>
        <Typography fontWeight={"bold"} variant={"h4"}>{question?.question}</Typography>
        <Typography variant={"subtitle2"}>No chart for this type of question ({question?.type})</Typography>
    </Stack>


    return (
        question
        && answers
        && <Stack direction={"column"} gap={1} sx={{height: "100%"}}>
            {
                {
                    [QuestionTypes.VOICE]: <VoiceQuestionGraph setActiveQuestion={setActiveQuestion} collapsable={collapsable}
                                                 title={question?.question}
                                                 subtitle={"Voice question"} question={question}
                                                 answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    [QuestionTypes.TEXT]: <VoiceQuestionGraph setActiveQuestion={setActiveQuestion} collapsable={collapsable}
                                                title={question?.question}
                                                subtitle={"Text question"} question={question}
                                                answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    [QuestionTypes.SLIDER]: <SliderGraph setActiveQuestion={setActiveQuestion} collapsable={collapsable}
                                                title={question?.question}
                                                subtitle={"Text question"} question={question}
                                                answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    [QuestionTypes.MULTICHOICE]: <MultipleChoiceGraph title={question?.question} subtitle={"Multiple choice question"}
                                                        question={question} answers={answers} index={index} collapsable={collapsable}
                                                        secondaryAction={removeFromReport}/>,
                    [QuestionTypes.CHECKBOXES]: <MultipleChoiceGraph title={question?.question} subtitle={"Checkbox question"}
                                                       question={question} answers={answers} index={index} collapsable={collapsable}
                                                       secondaryAction={removeFromReport}/>,
                    [QuestionTypes.DROPDOWN]: <MultipleChoiceGraph title={question?.question} subtitle={"Dropdown question"}
                                                     question={question} collapsable={collapsable}
                                                     answers={answers} index={index}
                                                     secondaryAction={removeFromReport}/>,
                    [QuestionTypes.IMAGE]: <ImageQuestionPreview title={question?.question} subtitle={"Image question"}
                                                   question={question} collapsable={collapsable}
                                                   answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    [QuestionTypes.LOCATE_USER]: <MapOfResponses title={question?.question} subtitle={"Geo-locate user question"}
                                                  question={question} answers={answers} index={index} collapsable={collapsable}
                                                  secondaryAction={removeFromReport}/>,
                    [QuestionTypes.MAPINPUT]: <MapOfResponses title={question?.question} subtitle={"Map question"} question={question} collapsable={collapsable}
                                                answers={answers} index={index} secondaryAction={removeFromReport}/>,
                }[question?.type]
            }
        </Stack>
    )
}
