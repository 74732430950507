import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {AppBar, Box, Button, IconButton, Stack, Toolbar} from "@mui/material";
import logoImg from "../../assets/logo/Ella.svg";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import {auth} from "../../utils/firebaseApp";

export const Header = ({toggleMenu, open}) => {

    return (
        <AppBar
            position="fixed"
            color={"inherit"}
            elevation={0}
            sx={{
                zIndex: 999,
                width: "100%",
                display: "block",
                px: 0
            }}
        >
            <Toolbar
                sx={{
                    py: 0.5, pl: '8px !important',
                    width: "100%",
                    display: "block",
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{width: "100%"}}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{display: {xs: "none", md: 'flex'} }}
                    >
                        <img src={logoImg} alt={"Logo"} height={60}/>
                    </Stack>
                    {auth.currentUser ? <Box sx={{display: "grid"}}>
                        <Box sx={{display: {xs: "grid", md: "none"}}}>
                            <IconButton
                                sx={{padding: "8px 8px 0px 8px"}}
                                onClick={()=>toggleMenu(!open)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        <Button
                            component={Link}
                            to={"/create-survey"}
                            sx={{
                                padding: "6px 18px",
                                display: {xs: "none", md: "inline-flex"},
                            }}
                            variant={"contained"}
                            className={"gradient-btn"}
                        >
                            <AddIcon sx={{height: "18px"}}/>
                            <Typography sx={{fontSize: "1em"}}>Create Survey</Typography>
                        </Button>
                    </Box> : null}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};


Header.propTypes = {
    toggleMenu: PropTypes.func,
    open: PropTypes.bool,
};

