import {useAtomValue} from "jotai";
import {AnswerAtom} from "../atoms";
import React from "react";
import {Stack, Typography} from "@mui/material";
import {enums} from "../../../utils/enums";
import {TextQuestion} from "./TextQuestion";
import {VoiceQuestion} from "./VoiceQuestion";
import {MultiChoiceQuestion} from "./MultiChoiceQuestion";
import {CheckBoxQuestion} from "./CheckBoxQuestion";
import {SliderQuestion} from "./SliderQuestion";
import {DropdownQuestion} from "./DropdownQuestion";
import {MapInputQuestion} from "./MapInputQuestion";
import {SectionQuestion} from "./SectionQuestion";
import {GeoLocationQuestion} from "./GeoLocationQuestion";
import {ImageQuestion} from "./ImageQuestion";

export const Question = () => {
  const answer = useAtomValue(AnswerAtom);

  return (
    <Stack direction={"column"} gap={2} sx={{maxHeight: "100%", flex: 1, overflowY: "auto", overflowX: "hidden", p: 2, position: "relative"}}>
    {
        {
          [enums.TEXT]: <TextQuestion />,
          [enums.VOICE]: <VoiceQuestion />,
          [enums.MULTICHOICE]: <MultiChoiceQuestion />,
          [enums.CHECKBOXES]: <CheckBoxQuestion />,
          [enums.SLIDER]: <SliderQuestion />,
          [enums.DROPDOWN]: <DropdownQuestion />,
          [enums.MAPINPUT]: <MapInputQuestion />,
          [enums.SECTION]: <SectionQuestion />,
          [enums.LOCATE_USER]: <GeoLocationQuestion />,
          [enums.IMAGE]: <ImageQuestion />,
          [undefined]: <Typography>Question type not found...</Typography>,
          [null]: <Typography>Question type not found...</Typography>
        }[answer?.type]
      }
      {/*<pre>*/}
      {/*  {JSON.stringify(answer, null, 4)}*/}
      {/*</pre>*/}
    </Stack>
  )
}
