import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme} from "@mui/material";
import Box from "@mui/material/Box";

export const confirmDialogOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <Dialog
                open={!!message}
                PaperProps={{
                    sx: {
                        borderRadius: 4,
                        p: {xs: 2, sm: 3, md: 4}
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" component={'div'}>
                    <Typography variant={'h5'}>{message.title}</Typography>
                </DialogTitle>
                <DialogContent component={'div'}>
                    <Typography variant={'body1'}>{message?.subtitle}</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} gap={2}>
                        <Box sx={{flex: 1}}>
                            <Button variant={"outlined"} sx={{flex: 1, width: '100%', borderRadius: 2, px: 2}}  onClick={onCancel}>{message?.cancelText || "Close"}</Button>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <Button variant={"contained"} className={"gradient-btn"} sx={{flex: 1, px: 2, minWidth: "0px !important"}} onClick={onConfirm} autoFocus>{message?.confirmText || "Confirm"}</Button>
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>
        );
    }
}
