import React from "react";
import {saveAs} from 'file-saver';
import axios from "axios";
import {enums} from "./enums";
import {db, firebaseApp} from "./firebaseApp";
import uuid from "react-uuid";
import {collection, doc, getDocs, query, setDoc, updateDoc, where, writeBatch} from "firebase/firestore";
import {toast} from "react-toastify";
import {stopwords} from "./stopwords";

//<editor-fold desc="Old Functions">
export function getFirstObjectFromArrayThatMatchesValue(array, propertyToLookFor, valueToMatch) {
    return array.filter(object => {
        return object[propertyToLookFor] === valueToMatch
    })[0]
}

export function getObjectArrayFromArrayThatMatchesValue(array, propertyToLookFor, valueToMatch) {
    return array.filter(object => {
        return object[propertyToLookFor] === valueToMatch
    })
}

export function pad0s(time) {
    return time < 10 ? "0" + time : time
}

export function sortArrayOfObjectsByStringProperty(array, propertyToSortBy) {
    return array.sort((a, b) => (a[propertyToSortBy] > b[propertyToSortBy]) ? 1 : ((b[propertyToSortBy] > a[propertyToSortBy]) ? -1 : 0))
}

export function getBlankSurveyObject(userUid) {
    return {
        uid: uuid(),
        userUid: userUid,
        dateCreated: Date.now(),
        questions: [],
        translations: [{language: 'en', uid: uuid()}],
        // getUserGPSLocation: false,
        getUserIPAddress: false,
        saveAudioRecordings: false,
        // surveyName: 'New '+ uniqueNamesGenerator({
        //     dictionaries: [colors],
        //     style: 'lowerCase',
        //     separator: "-"
        // }) + " survey",
        surveyName: "",
        surveyDescription: '',
        languages: [{
            "Name": "English (United States)",
            "BCP47": "en-US",
            "Native": "English"
        }],
        archived: false
    }
}

export function getArrayOfObjectsWithRemovedItem(array, itemPropertyName, itemPropertyValueToDelete) {
    return array.filter(object => {
        return object[itemPropertyName] !== itemPropertyValueToDelete
    })
}

export function getListOfQuestionsForSurvey(questionObjects, surveyObject) {
    if (!surveyObject.uid) return undefined
    if (!questionObjects) return undefined
    return questionObjects.filter(questionObject => {
        return questionObject.surveyUid === surveyObject.uid
    })
}

export function saveAsCsv(blobToSave, fileName) {
    saveAs(blobToSave, fileName + " by Ella.csv")
}

export function getSurveyObject(surveyObjects, surveyUid) {
    return surveyObjects.filter(surveyObject => {
        return surveyObject.surveyUid === surveyUid
    })[0]
}

export function getQuestionFromListOfQuestions(questionObjects, questionUid) {
    return questionObjects.filter(surveyObject => {
        return surveyObject.questionUid === questionUid
    })[0]
}

export function getObjectFromArrayThatMatchesValue(array, propertyToLookFor, valueToMatch) {
    return array.filter(object => {
        return object[propertyToLookFor] === valueToMatch
    })[0]
}

export function extractAnswersFromSurveyObject(surveyObject) {
    let answersArray = []
    surveyObject.questions.forEach(questionObject => {
        questionObject.answers.forEach(answerObject => answersArray.push(answerObject))
    })
    return answersArray
}

export function sortAnswersByUser(answersArray) {
    let listOfUniqueUsers = [...new Set(answersArray.map(item => item.respondentUid))];

    let listOfRespondentObjects = []

    listOfUniqueUsers.forEach(respondentUid => {
        let respondentObject = {respondentUid: respondentUid}
        const answerObjects = answersArray.filter(answer => {
            return answer.respondentUid === respondentUid
        })
        sortArrayOfObjectsByStringProperty(answerObjects, 'timestamp')
        respondentObject.answerObjects = answerObjects
        respondentObject.timestamp = answerObjects[0].timestamp ? answerObjects[0].timestamp : 0
        listOfRespondentObjects.push(respondentObject)
    })
    sortArrayByNumericObjectProperty(listOfRespondentObjects, "timestamp")
    return listOfRespondentObjects
}

export const MAX_NUMBER_OF_FREE_ANSWERS = 15

export function getListOfAnswersForQuestion(answerObjects, questionObject, userObject) {

    let answerObjectsArray = answerObjects.filter(answerObject => {
        return answerObject.questionUid === questionObject.questionUid
    })

    // Treat answers to show or not show if the user is standard
    let i;
    for (i = 0; i < answerObjectsArray.length; i++) {
        answerObjectsArray[i]['shouldShowAnswer'] = !(i > MAX_NUMBER_OF_FREE_ANSWERS && userObject.accountType === 'standard');
    }
    return answerObjectsArray
}

export function sortArrayByNumericObjectProperty(array, objectProperty) {
    return array.sort((a, b) => {
        return (+a[objectProperty]) > (+b[objectProperty]) ? 1 : -1
    })
}

export function isFreeTrial(userObject) {
    // the number compared to is 1 week in miliseconds

    return Date.now() - userObject.dateStarted <= 604800000
}

export function isProUser(userObject) {
    return userObject.accountType === 'pro'
}

export function userCanAddSurvey(userObject, arrayOfObjects, showToast) {
    // console.log(userObject)
    // console.log(arrayOfObjects)
    if (userObject.accountType === "pro") {
        return true;
    } else if (userObject.accountType === 'standard' && (arrayOfObjects.length < 1 || arrayOfObjects === null || arrayOfObjects === undefined)) {
        return true;
    } else {
        showToast("error", "Subscribe to create more surveys, redirecting you to subscriptions page in 3s")
        return false;
    }
}

export function getTextAnswerValue(questionUid, textAnswers) {
    let answerObject = textAnswers.filter(answer => {
        return answer.questionUid === questionUid
    })[0]
    if (answerObject) {
        return answerObject.answer;
    } else {
        return ""
    }
}

export function getArrayWithUpdatedObjectThatMatchesValue(array, objectPropertyToInspect, valueToMatch, updatedObject) {
    let newArray = array
    newArray.forEach((object, index) => {
        if (object[objectPropertyToInspect] === valueToMatch) {
            array[index] = updatedObject
        }
    })
    return newArray
}

export function getObjectFromArrayByProperty(array, objectPropertyToInspect, valueToMatch) {
    return array.filter((object) => {
        return object[objectPropertyToInspect] === valueToMatch
    })[0]
}

export async function updateQuestionsInDb(questionObjectsToUpdate, user) {
    let authToken = await user.getIdToken()
    return new Promise(async (res, rej) => {
        try {
            let response = await axios({
                method: "POST",
                url: enums.serverUrl + '/updateQuestions',
                data: {
                    authToken: authToken,
                    questionObjects: questionObjectsToUpdate
                }
            })
            res(response.status)
        } catch (e) {
            rej("fail")
        }
    })
}

export async function updateAnswersInDb(answerObjectsToUpdate, user) {
    let authToken = await user.getIdToken()
    return new Promise(async (res, rej) => {
        try {
            let response = await axios({
                method: "POST",
                url: enums.serverUrl + '/updateAnswers',
                data: {
                    authToken: authToken,
                    answerObjects: answerObjectsToUpdate
                }
            })
            console.log('updated answers')
            res(response.status)
        } catch (e) {
            rej("fail")
        }
    })
}

export async function updateSurveyInDb(surveyObjectToUpdate, user) {
    let authToken = await user.getIdToken()
    return new Promise(async (res, rej) => {
        try {
            let response = await axios({
                method: "POST",
                url: enums.serverUrl + '/updateSurvey',
                data: {
                    authToken: authToken,
                    surveyObject: surveyObjectToUpdate
                }
            })
            console.log('updated survey')
            res(response.status)
        } catch (e) {
            rej("fail")
        }
    })
}

export async function updateUserInDb(newUserObject, currentUser) {
    let authToken = await currentUser.getIdToken()
    return new Promise(async (res, rej) => {
        try {
            let response = await axios({
                method: "POST",
                url: enums.serverUrl + '/updateUser',
                data: {
                    authToken: authToken,
                    newUserObject: newUserObject
                }
            })
            res(response.status)
        } catch (e) {
            rej("fail")
        }
    })
}

export async function getNotificationsForUser(currentUser) {
    let authToken = await currentUser.getIdToken()
    return new Promise(async (res, rej) => {
        try {
            let response = await axios({
                method: "POST",
                url: enums.serverUrl + '/fetchnotifications',
                data: {
                    authToken: authToken,
                }
            })
            res(response.data)
        } catch (e) {
            rej("fail")
        }
    })
}

export async function updateEachQuestionOrderNumberInDb(questionObjects, updatedQuestionObjects, user, dispatch) {
    await updateQuestionsInDb(updatedQuestionObjects, user)
    dispatch({questionObjects: updatedQuestionObjects})
}

// export async function addUpdateQuestionToDb(questionObject, questionObjects, dispatch, showToast) {
//   return new Promise((res, rej) => {
//     axios({
//       method: "POST",
//       url: enums.serverUrl + '/addquestion',
//       data: questionObject,
//     }).then(response => {
//       if (response.status === 200) {
//
//         dispatch({loadingBarProgress: 100})
//         showToast("success", "Question added!");
//       }
//       res()
//     })
//       .catch(function (error) {
//         if (error.response) {
//           dispatch({loadingBarProgress: 100})
//           showToast("error", "Something went wrong, please try again!")
//         }
//         rej()
//       });
//   })
// }

export async function fetchUserDataPublic(uid) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: enums.serverUrl + '/fetchalluserdatapublic',
            data: {
                uid: uid
            },
        }).then(response => {
            if (response.status === 200) {
                res(response.data.userData)
            }
        })
            .catch(function (error) {
                if (error.response) {
                    // showToast("error", "Something went wrong, please try again!")
                }
                rej()
            });
    })
}

export async function updateQuestionInDb(questionObject, questionObjects, dispatch, showToast, authToken) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: enums.serverUrl + '/updateQuestion',
            data: {
                questionObject: questionObject,
                authToken: authToken
            }
        }).then(response => {
            if (response.status === 200) {
                let questionObjectWithoutTheChangedQuestion = getArrayOfObjectsWithRemovedItem(questionObjects, 'questionUid', questionObject.questionUid)
                dispatch({
                    questionObjects: sortArrayByNumericObjectProperty([...questionObjectWithoutTheChangedQuestion, questionObject], "orderNumber")
                })
                dispatch({loadingBarProgress: 100})
                showToast("success", "Success!");
            }
            console.log('responding')
            res()
        }).catch(function (error) {
            if (error.response) {
                dispatch({loadingBarProgress: 100})
                console.log(error)
                showToast("error", "Something went wrong, please try again!")
            }
            console.log('rejecting')
            console.log(error)
            rej()
        });
    })
}

export async function uploadVoiceAndGetDownloadLink(questionUid, audioFile) {
    return new Promise(async (res, rej) => {
        try {
            let storageRef = firebaseApp.storage().ref();
            const metadata = {
                contentType: 'audio/webm',
            };

            let uploadTask = await storageRef.child('audioFiles/' + questionUid).put(audioFile, metadata);
            let downloadUrl = await uploadTask.ref.getDownloadURL()

            console.log('downloadUrl', downloadUrl)
            res(downloadUrl)

        } catch (e) {
            console.log(e)
            console.log("Error uploading the image")
            rej()
        }
    })
}

export function locateUser() {
    navigator.geolocation.getCurrentPosition(function (position) {
        console.log(position)
        return position
        // setViewport({longitude: position.coords.longitude, latitude: position.coords.latitude, zoom: 20})
        // setUserLocation({longitude: position.coords.longitude, latitude: position.coords.latitude})
    });
}

export function saveSelectedSurveyAsCSV(surveyObject) {
    console.log('surveyObject')
    console.log(surveyObject)
    let consumerCsvFileURl = createConsumerCSVFileUrl(surveyObject)
    saveAsCsv(consumerCsvFileURl, surveyObject.surveyName)
}

export function createConsumerCSVFileUrl(surveyObject) {
    let csv = ''
    let row = ''
    let headers = ''
    headers = headers.concat(
        "respondent_uid", ',',
    )
    let columnIndex = 1
    let columnMapping = []

    sortArrayByNumericObjectProperty(surveyObject.questions, 'orderNumber').forEach(questionObject => {
        console.log('questionObject')
        console.log(questionObject)
        headers = headers.concat("\"" + questionObject.question + "\"" + ",")
        columnMapping.push({columnIndex: columnIndex, uid: questionObject.uid})
    })
    csv = csv.concat(headers, "\n")

    let respondentsAnswerObjects = sortAnswersByUser(surveyObject.answers)
    console.log('respondentsAnswerObjects')
    console.log(respondentsAnswerObjects)
    respondentsAnswerObjects.forEach(respondentAnswerObject => {
        row = ''
        row = row.concat("\"" + respondentAnswerObject.respondentUid + "\"", ',')
        console.log(columnMapping)
        columnMapping.forEach(mappedQuestion => {
            let questionUid = mappedQuestion.uid
            console.log('questionUid')
            console.log(questionUid)
            let answerForAGivenQuestion = respondentAnswerObject.answerObjects.filter(answerObject => {
                return answerObject.questionUid === questionUid
            })[0]
            console.log('answerForAGivenQuestion')
            console.log(answerForAGivenQuestion)
            if (answerForAGivenQuestion) {
                console.log('answerForAGivenQuestion')
                console.log(answerForAGivenQuestion)
                switch (answerForAGivenQuestion.type) {
                    case enums.IMAGE:
                        row = row.concat("\"" + answerForAGivenQuestion.photoUrls + "\"", ',')
                        break;
                    case enums.MAPINPUT:
                        row = row.concat("\"" + answerForAGivenQuestion.pois.map(poi => poi.coordinates.lat + "," + poi.coordinates.lng).join(';') + "\"", ',')
                        break;
                    default:
                        row = row.concat("\"" + answerForAGivenQuestion.text + "\"", ',')
                }
                // if ([enums.IMAGE].includes(answerForAGivenQuestion.type)) {
                //     row = row.concat("\"" + answerForAGivenQuestion.photoUrls + "\"", ',')
                // } else if (answerForAGivenQuestion) {
                //     row = row.concat("\"" + answerForAGivenQuestion.text + "\"", ',')
                // } else {
                //     row = row.concat('', ',')
                // }
            } else {
                row = row.concat('', ',')
            }
        })

        row = row.concat('\n')

        csv = csv.concat(row)
    })

    return new Blob([csv], {type: 'application/csv'})
}

export function updateSurveyObjectsWithAnswers(surveyObjects, questionObjects, dispatch, answerObjects, userObject) {
    let completeSurveyObjects = []
    surveyObjects.forEach(surveyObject => {
        surveyObject["questions"] = []
        let listOfQuestionsForSurvey = getListOfQuestionsForSurvey(questionObjects, surveyObject)

        listOfQuestionsForSurvey.forEach(questionObject => {
            questionObject['answers'] = getListOfAnswersForQuestion(answerObjects, questionObject, userObject)
            surveyObject["questions"].push(questionObject)
        })
        completeSurveyObjects.push(surveyObject)
    })
    dispatch({surveyObjectsWithAnswersAndQuestions: completeSurveyObjects});
}

export async function fetchAnswers(user, dispatch, showToast) {
    let authToken = await user.getIdToken()
    axios({
        method: "POST",
        url: enums.serverUrl + '/fetchallanswers',
        data: {
            authToken: authToken
        }
    }).then(response => {
        dispatch({answerObjects: response.data.answerObjects});
        showToast("success", "Answers Fetched!")
        // createCsv()
    }).catch(error => {
        showToast("error", "\"Something went wrong, please please refresh\"!")
    })
}

export function getWordFrequency(string) {
    /* Below is a regular expression that finds alphanumeric characters
       Next is a string that could easily be replaced with a reference to a form control
       Lastly, we have an array that will hold any words matching our pattern */
    console.log('string')
    console.log(string)
    let pattern = /\w+/g,
        // string = "I I am am am yes yes.",
        matchedWords = string.match(pattern);

    /* The Array.prototype.reduce method assists us in producing a single value from an
       array. In this case, we're going to use it to output an object with results. */
    let counts = matchedWords.reduce(function (stats, word) {

        /* `stats` is the object that we'll be building up over time.
           `word` is each individual entry in the `matchedWords` array */
        if (stats.hasOwnProperty(word)) {
            /* `stats` already has an entry for the current `word`.
               As a result, let's increment the count for that `word`. */
            stats[word] = stats[word] + 1;
        } else {
            /* `stats` does not yet have an entry for the current `word`.
               As a result, let's add a new entry, and set count to 1. */
            stats[word] = 1;
        }

        /* Because we are building up `stats` over numerous iterations,
           we need to return it for the next pass to modify it. */
        return stats;

    }, {});

    /* Now that `counts` has our object, we can log it. */
    console.log(counts);
    return counts
}

export function calculateWordFrequency(text) {
// const stopwords = ['the', 'is', 'and', 'a', 'to', 'of', 'in']; // Example stopwords list
    // Convert the text to lowercase and split it into an array of words
    const words = text.toLowerCase().split(/\W+/);

    // Create an object to store the word frequencies
    const wordFrequency = {};

    // Iterate over each word
    for (const word of words) {
        // Skip stopwords
        if (stopwords.includes(word)) {
            continue;
        }

        // Increment the word frequency count
        if (word in wordFrequency) {
            wordFrequency[word]++;
        } else {
            wordFrequency[word] = 1;
        }
    }

    return wordFrequency
}

export function calculateWordFrequencyAndReturnAsObject(text) {
    const wordFrequency = calculateWordFrequency(text)
    return Object.keys(wordFrequency).map((word) => ({
        text: word,
        value: wordFrequency[word],
    }));
}

export function sortByKey(array, key) {
    // Use the Array.sort() method with a custom comparison function
    array.sort(function (a, b) {
        // Access the value of the specified key for comparison
        const valueA = a[key];
        const valueB = b[key];

        // Compare the values and return the appropriate value
        if (valueA > valueB) {
            return -1;
        } else if (valueA < valueB) {
            return 1;
        } else {
            return 0;
        }
    });

    return array; // The array is now sorted by the specified key
}

export function sortMapByValuesAndPushToArray(dict) {
    // Create items array
    let items = Object.keys(dict).map(function (key) {
        return [key, dict[key]];
    });

    // Sort the array based on the second element
    items.sort(function (first, second) {
        return second[1] - first[1];
    });

    return items
}

export function getAllAnswersFromQuestionAsSingleString(currentQuestion) {
    console.log('currentQuestion')
    console.log(currentQuestion)
    let singleStringWithAllAnswers = ''
    currentQuestion.answers.forEach(answer => {
        if (answer.answer) {
            singleStringWithAllAnswers += answer.answer + " "
        } else if (answer.translation) {
            singleStringWithAllAnswers += answer.translation + " "
        } else if (answer.transcription) {
            singleStringWithAllAnswers += answer.transcription + " "
        }
    })
    return singleStringWithAllAnswers
}

export function playAudio(download_url) {
    const audio = new Audio(download_url);
    audio.play()
      .then(() => {
          console.log("Audio playback started successfully.");
      })
      .catch((error) => {
          console.error("Error playing audio:", error);
      });
}

//</editor-fold>

//<editor-fold desc="New Functions">
export async function addUpdateQuestionToDb(questionObject) {
    // console.log('adding', questionObject)
    try {
        const ref = doc(db, "questions", questionObject.uid);
        await updateDoc(ref, questionObject)
        // console.log('success')
    } catch (e) {
        console.log(e.code === 'not-found')
        if (e.code === 'not-found') {
            try {
                // console.log('setting')
                await setDoc(doc(db, "questions", questionObject.uid), questionObject)
            } catch (e) {
                console.log(e)
            }
        }

    }
}

export async function addUpdateAnswerToDb(answerObject) {
    // console.log('adding', questionObject)
    const collectionName = 'answers'
    try {
        const ref = doc(db, collectionName, answerObject.uid);
        await updateDoc(ref, answerObject)
        // console.log('success')
    } catch (e) {
        console.log(e.code === 'not-found')
        if (e.code === 'not-found') {
            try {
                // console.log('setting')
                await setDoc(doc(db, collectionName, answerObject.uid), answerObject)
            } catch (e) {
                console.log(e)
            }
        }

    }
}

export function createAnswerObject(questionObject, respondentUid) {
    return {
        userUid: questionObject.userUid,
        uid: uuid(),
        // date: Date.now(),
        question: questionObject.question,
        questionUid: questionObject.uid,
        surveyUid: questionObject.surveyUid,
        respondentUid: respondentUid,
        collectionUid: questionObject.collectionUid,
        languageObject: questionObject.languageObject,
        timestamp: Date.now(),
        type: questionObject.type,
        text: '',
        translation: '',
        originalReferenceUid: questionObject.originalReferenceUid
    }
}

export function answerExists(array, questionUid) {
    return array.filter(answer => {
        return answer.questionUid === questionUid
    }).length === 1
}

export function countDistinctWords(text) {
    console.log('text')
    console.log(text)
    // Remove punctuation and convert to lowercase
    text = text.replace(/[^\w\s]/g, '').toLowerCase();
    // Split text into an array of words
    const words = text.split(' ');

    // Create an empty object to store the counts of each word
    const wordCounts = {};

    // Iterate over each word in the array and increment its count in the object
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (word in wordCounts) {
            wordCounts[word]++;
        } else {
            wordCounts[word] = 1;
        }
    }

    const wordCountsFormatted = Object.keys(wordCounts)
    wordCountsFormatted.forEach((word, index) => {
        wordCountsFormatted[index] = {text: word, value: wordCounts[word]}
    })

    console.log(JSON.stringify(wordCountsFormatted))
    //TODO: if you return a string just as is, it will work, if you return the same exact string but in a variable it doesnt
    // return [{"text": "test", "value": 10}, {"text": "test", "value": 1}]
    return JSON.stringify(wordCountsFormatted)
}

export function countDistinctObjectsByDay(objects, timestampKey) {
    // Create an empty object to store the counts for each day
    const countsByDay = {};

    // Iterate over each object in the array
    for (let i = 0; i < objects.length; i++) {
        const object = objects[i];

        // Get the timestamp value and convert it to a Date object
        const timestamp = new Date(object[timestampKey]);

        // Extract the date portion of the timestamp
        const date = timestamp.toISOString().slice(0, 10);

        // Increment the count for the corresponding date in the countsByDay object
        if (date in countsByDay) {
            countsByDay[date]++;
        } else {
            countsByDay[date] = 1;
        }
    }
    return countsByDay;
}

export async function updateAPIkeysInDB(apiKeys, currentUser) {
    const ref = doc(db, "users", currentUser.uid);
    await updateDoc(ref, {apiKeys: apiKeys, nakedApiKeys: apiKeys.map(apiKey => apiKey.key)})
}

export async function deleteSurvey(surveyUid, answerObjects, questionObjects, surveys, dispatch, surveyObject, reports) {
    dispatch({surveyObject: getObjectFromArrayThatMatchesValue(surveys, 'uid', surveyUid)})
    let answerObjectsToDelete = answerObjects.filter(questionObject => questionObject.surveyUid === surveyObject.uid)
    let questionObjectsToDelete = questionObjects.filter(answerObject => answerObject.surveyUid === surveyObject.uid)
    try {
        const batch = writeBatch(db);
        answerObjectsToDelete.forEach(answerObject => {
            batch.delete(doc(db, 'answers', answerObject.uid))
        })
        questionObjectsToDelete.forEach(questionObjects => {
            batch.delete(doc(db, 'questions', questionObjects.uid))
        })
        reports.forEach(questionObjects => {
            batch.delete(doc(db, 'questions', questionObjects.uid))
        })
        batch.delete(doc(db, 'surveys', surveyObject.uid))
        const result = await batch.commit();
        dispatch({surveys: surveys.filter(survey => surveyUid !== survey.uid)})
        dispatch({questionObjects: questionObjects.filter(object => surveyUid !== object.surveyUid)})
        dispatch({answerObjects: answerObjects.filter(object => surveyUid !== object.surveyUid)})

        toast.success('Survey deleted')
    } catch (e) {
        console.log(e)
        toast.error("Something went wrong please try again")
    }
}

export function countTopics(array) {
    const topicCounts = {};

    // Count the occurrence of each topic
    for (let i = 0; i < array.length; i++) {
        const topic = array[i].topic;
        if (topicCounts[topic]) {
            topicCounts[topic]++;
        } else {
            topicCounts[topic] = 1;
        }
    }

    // Convert topic counts to an array of objects
    const topicCountsArray = Object.keys(topicCounts).map(topic => ({
        topic: topic,
        count: topicCounts[topic]
    }));

    return topicCountsArray;
}

export function findIndexByProperty(arr, propertyName, propertyValue) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i][propertyName] === propertyValue) {
            return i; // Return the index of the object when the property value matches
        }
    }
    return -1; // Return -1 if the object with the specified property value is not found
}

export function removeDuplicatesByProperty(arr, prop) {
    const uniqueRef = [];
    const unique = [];
    arr.map((object) => {
        if (!uniqueRef.includes(object[prop])) {
            uniqueRef.push(object[prop]);
            unique.push(object);
        }
    })

    return unique;
}

export async function getQuestionsFromDB(currentUser) {
    const questionsQuery = query(collection(db, "questions"), where("userUid", "==", currentUser.uid));
    const questionSnapshot = await getDocs(questionsQuery);
    let questionObjects = []
    questionSnapshot.forEach((doc) => {
        questionObjects.push(doc.data())
    })
    return questionObjects
}

//</editor-fold>
