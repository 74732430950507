import {TextField} from "@mui/material";
import React from "react";


export const InputText = ({value, onChange}) => {

  return (
    <TextField variant="outlined"
               fullWidth autoFocus
               multiline minRows={3} maxRows={6}
               placeholder="Insert your answer here"
               value={value}
               helperText={"If you want new row press Shift + Enter"}
               onKeyDown={(e) => {
                 if (e.key === "Enter" && !e.shiftKey) {
                   e?.preventDefault();
                   const form = e?.target?.closest("form");
                   if (form) {
                     form.requestSubmit();
                   }
                 }
               }}
               onChange={onChange}
    />
  )
}