import React, {useContext, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {Divider} from "@mui/material";
import {AppContext} from "../../utils/AppContext";
import SurveySelectorDropdowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {useNavigate, useParams} from "react-router-dom";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {SurveyTokens} from "./components/survey-tokens";
import {ApiKeys} from "./components/api-keys";

export const ApiPage = () => {
    const {state: {surveys, tokens}, dispatch} = useContext(AppContext);
    const [activeSurvey, setActiveSurvey] = useState(null);
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSurvey = async () => {
            try {
                setLoading(true);

                const docRef = doc(db, "surveys", id);
                const surveySnapshot = await getDoc(docRef);
                if (!surveySnapshot.exists()) navigate('/404');

                setActiveSurvey({...surveySnapshot.data()});

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        if (surveys?.length) {
            if (!id){
                const surveyUid = surveys[0]?.uid || null;
                if (surveyUid) {
                    navigate(`/api/${surveyUid}`)
                }
            } else {
                const survey = surveys?.find(i => i?.uid === id);
                if (survey) {
                    setActiveSurvey(survey);
                } else {
                    fetchSurvey();
                }
            }
        }
    }, [id, surveys])

    useEffect(() => {
        async function getTokens() {
            console.log('get tokens')
            const tokensFromServer = []
            const tokensQuery = query(
                collection(db, "tokens"),
                where("surveyUid", "==", activeSurvey?.uid)
            );
            const tokensSnapshot = await getDocs(tokensQuery);
            if (tokensSnapshot.empty) return;
            tokensSnapshot.forEach((doc) => {
                tokensFromServer.push(doc.data());
            });
            await dispatch({tokens: [...tokensFromServer, ...tokens]})
        }

        if (!activeSurvey) return;

        if (!tokens || !tokens.some(token => token.surveyUid === activeSurvey?.uid)) {
            getTokens()
        }

    }, [activeSurvey]);

    if (loading) return <LoadingScreen />

    return (
        <>
            <Stack
                direction={"column"}
                maxWidth={"lg"}
                gap={2}
                sx={{pt: 2, mx: {xs: 2, md: "auto"}, pb: 15}}
            >
                <SurveySelectorDropdowns
                    surveys={surveys}
                    setActiveSurvey={(survey) => survey && navigate(`/api/${survey?.uid}`)}
                    activeSurvey={activeSurvey}
                />
                <SurveyTokens survey={activeSurvey} />

                <Divider/>

                <ApiKeys survey={activeSurvey} />
            </Stack>
        </>
    );
}
