import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import React from "react";
import {IconButton, InputAdornment, Slider, Stack, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";


const sliderStyle = {
  color: '#60abce',
  height: 5, py: '0 !important',
  justifySelf: 'stretch',
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5
  },

}

export const SliderQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  return (
    <>
      <QuestionTitle title={answer?.question ? <>{answer?.question} {answer?.required ? <span style={{color: "red"}}>*</span> : null}</> : "-"} />
      <Box sx={{width: {xs: "85%", md: "100%"}, mx: "auto"}}>
        <Slider
          value={answer?.text || answer?.from || 0}
          sx={sliderStyle}
          min={answer?.from ? +answer?.from : 0}
          max={answer?.to ? +answer?.to : 100}
          onChange={(e) => setAnswer(prev => ({...prev, text: +e?.target?.value}))}
          aria-labelledby="input-slider"
        />
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pt: 0.5}}>
          <Typography fontSize={"small"}>Min: {answer?.from}</Typography>

          <Typography fontSize={"small"}>Max: {answer?.to}</Typography>
        </Stack>
      </Box>

      <Box>
        <TextField
          type="number"
          size={"small"}
          value={answer?.text || answer?.from || ""}
          onChange={(e) => setAnswer(prev => ({...prev, text: +e?.target?.value}))}
          sx={{
            minWidth: "100px", width: "100px",
            '& .MuiInputBase-root': {p: '3px !important'}
          }}
          error={answer?.text < answer?.from || answer?.text > answer?.to}
          helperText={(answer?.text < answer?.from || answer?.text > answer?.to) && "value must be between " + answer?.from + " and " + answer?.to}
          InputProps={{
            min: answer?.from ? +answer?.from : 0,
            max: answer?.to ? +answer?.to : 100,
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction={"column"}>
                  <IconButton size={"small"} onClick={() => {
                    const maxValue = +answer?.to || 100;
                    const step = Math.ceil(maxValue / 10);

                    setAnswer(prev => ({...prev, text: +prev?.text + step > maxValue ? maxValue : +answer?.text + step}));
                  }}>
                    <AddIcon sx={{fontSize: 10}}/>
                  </IconButton>
                  <IconButton size={"small"} onClick={() => {
                    const minValue = +answer?.from || 0;
                    const maxValue = +answer?.to || 100;
                    const step = Math.ceil(maxValue / 10);

                    setAnswer(prev => ({...prev, text: +prev?.text - step < minValue ? minValue : +answer?.text - step}));
                  }}>
                    <RemoveIcon sx={{fontSize: 10}}/>
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  )
}