import React from "react";
import {Avatar, Box, Typography} from "@mui/material";
import {enums} from "../../../utils/enums";
import MapComponent from "../../../components/Map/MapComponent";
import {CardItem} from "../../../components/card/CardItem";
import {blue} from "@mui/material/colors";

export const MapOfResponses = ({question, answers, subtitle, title, index, secondaryAction, collapsable}) => {
    function mergePois(answers) {
        let mergedPois = []
        answers.forEach(answer => {
            switch (answer.type) {
                case enums.MAPINPUT:
                    mergedPois = mergedPois.concat(answer?.pois || [])
                    break
                case enums.LOCATE_USER:
                    if (answer?.lat?.toString() && answer?.lng?.toString()) {
                        mergedPois.push({
                            coordinates: {lat: answer?.lat, lng: answer?.lng},
                            label: answer.respondentUid
                        })
                    }
                    break
                default:
                    break
            }
        })
        // if (enums.MAPINPUT === question?.type) {
        //     answers.forEach(answer => {
        //         console.log(answer)
        //         mergedPois = mergedPois.concat(answer.pois)
        //     })
        // } else if (enums.LOCATE_USER === question.type) {
        //     answers.forEach(answer => {
        //         console.log(answer)
        //         mergedPois.push({
        //             coordinates: {lat: answer.lat, lng: answer.lng},
        //             label: answer.respondentUid
        //         })
        //     })
        // }
        return mergedPois
    }

    if (!question || !answers) return null

    return (
        <CardItem
            collapsable={collapsable}
            title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>{title}</Typography>}
            secondary={secondaryAction || null}
            subheader={<Typography fontSize={12} variant={"secondary2"} color={"#858585"}>{subtitle}</Typography>} >
            <Box sx={{width: "100%", height: "100%", mt: 1, mx: 0, px: 0}}>
                <MapComponent questionObject={question} pois={mergePois(answers)} showUserDot={false}/>
            </Box>
        </CardItem>
    )
}
